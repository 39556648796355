import useComponent from 'react-hooks/useComponent';

import { NavItem } from './utils';

function Nav({ links }) {
  const { toggleClasses } = useComponent();

  return (
    <nav className='nav hidden flex-row md:flex'>
      <ul className='nav__list-items flex flex-row gap-2 md:gap-4'>
        {links.map((link) => (
          <NavItem
            key={link.id}
            link={{
              path: link.link.path,
              target: link.link.target,
            }}
            icon={{ isLeading: false, isTrailing: false }}
            text={link.text}
            toggle={toggleClasses}
          />
        ))}
      </ul>
    </nav>
  );
}

export default Nav;
