import { useEffect } from 'react';
import useComponent from 'react-hooks/useComponent';
import { setVariants } from './utils';
export { FEATURES_VARIANT } from './utils';

function Feature({ variant, img, content, reverse, className }) {
  const { component, renderVariants } = useComponent(variant);

  const variants = setVariants(content, img, reverse, className);

  useEffect(() => {
    renderVariants(variants);
  }, []);

  return component;
}

export default Feature;
