import { useState } from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { useCallback } from 'react';
import useTheme from 'react-hooks/useTheme';

export default function useComponent(variant) {
  const [component, setComponent] = useState(null);
  const { darkMode } = useTheme();

  const classnames = useCallback((...args) => {
    return twMerge(clsx(args));
  }, []);

  const toggleClasses = useCallback(
    (className, darkModeClassName) => {
      return classnames(className, {
        [darkModeClassName]: darkMode,
      });
    },
    [darkMode, classnames]
  );

  const toggle = useCallback(
    (lightmode, darkmode) => {
      return darkMode ? darkmode : lightmode;
    },
    [darkMode]
  );

  // Updates variants

  const updateVariant = useCallback(
    (variants) => {
      if (variants && variants['default']) {
        setComponent(variants['default']());
      } else {
        console.error('Default variant not found in variants object.');
      }

      if (variants && variants[variant]) {
        setComponent(variants[variant]);
      } else {
        console.log(`Component variant ${variant} not found`);
      }
    },
    [variant]
  );

  // Renders variant after check

  const renderVariants = useCallback(
    (variants) => {
      if (variants && variants['default']) {
        updateVariant(variants);
      } else {
        console.error('Default variant not found in variants object.');
      }
    },
    [updateVariant]
  );

  return {
    component,
    renderVariants,
    toggle,
    toggleClasses,
  };
}
