function Video({ video, caption }) {
  return (
    <>
      <video
        className='h-full rounded-[inherit]'
        key={video.videoKey}
        autoPlay
        muted
      >
        <source src={video.src.source} type={video.type.type} />
        <source src={video.src.source2} type={video.type.type2}></source>
      </video>
      {caption && (
        <span className='text-gray-900 italic font-regular text-sm md:text-base'>
          {caption}
        </span>
      )}
    </>
  );
}

export default Video;
