import { renderComponent } from './utils';
export { BUTTON_VARIANTS } from './utils';

function Button({
  variant,
  hovered,
  defaultWidth,
  link,
  text,
  icon,
  className,
  ...props
}) {
  const component = renderComponent(
    variant,
    link,
    icon,
    text,
    defaultWidth,
    hovered,
    className,
    props
  );

  return <>{component}</>;
}

export default Button;
