import { useEffect, useMemo } from 'react';
import useComponent from 'react-hooks/useComponent';
import { setVariants } from './utils';

export { SECTION_VARIANTS } from './utils';
export { INFORMATION_VARIANTS } from './Information';
export { MEDIA_VARIANTS } from './Media';

function Section({ variant, heading, media, scenario, information, aside }) {
  const { component, renderVariants } = useComponent(variant);

  const variants = useMemo(
    () => setVariants(heading, aside, information, scenario, media),
    [heading, aside, information, scenario, media]
  );

  useEffect(() => {
    renderVariants(variants);
  }, [variants, renderVariants]);

  return <>{component}</>;
}

export default Section;
