import Image from './base-components/Image';
import Main from './base-components/Main';

export const switchAsideComponent = (img, content) => {
  return img ? (
    <Image img={img} />
  ) : (
    <Main
      title={content.secondary.title}
      paragraph={content.secondary.paragraph}
    />
  );
};
