import Search from 'assets/Steady_Paycheck/Search.png';
import Message from 'assets/Steady_Paycheck/message.svg';
import JobSeeker from 'assets/Steady_Paycheck/job-seeker.png';
import Courses from 'assets/Steady_Paycheck/course.svg';
import Jobs from 'assets/Steady_Paycheck/manage-jobs.png';
import Communication from 'assets/Steady_Paycheck/communication_wallpaper.png';

import Feature, { FEATURES_VARIANT } from 'UI/Feature';

export const setFeatures = () => {
  const features = [
    {
      id: 0,
      className:
        'border border-gray-600 h-[500px] overflow-hidden rounded-3xl xl:row-span-2',
      col: {
        md: 2,
      },
      component: (
        <Feature
          className='gap-8 lg:gap-12'
          content={{
            label: 'Empowering Your Search',
            paragraph:
              'Discover a comprehensive platform that simplifies your job search, enables seamless applications, and offers personalized recommendations tailored to your career goals.',
          }}
          img={{
            src: Search,
            alt: 'search-job',
          }}
        />
      ),
    },
    {
      id: 1,
      className:
        'border border-gray-600 h-[500px] overflow-hidden rounded-3xl xl:row-span-2',
      col: {
        md: 2,
        xl: 4,
      },
      component: (
        <Feature
          variant={FEATURES_VARIANT.RIGHT}
          content={{
            label: 'Simplifying Job Search and Applications',
            paragraph:
              'Easily connect with potential employers, streamlining your job search and simplifying the application process.',
          }}
          img={{
            src: JobSeeker,
            alt: 'simplify-job-search',
          }}
        />
      ),
    },
    {
      id: 2,
      className:
        'border border-gray-600 h-[500px] overflow-hidden rounded-3xl xl:row-span-2',
      col: {
        md: 2,
        xl: 4,
      },
      component: (
        <Feature
          variant={FEATURES_VARIANT.RIGHT}
          content={{
            label: 'Sharing Guidance and Expertise',
            paragraph:
              'This app empowers mentors to actively engage with aspiring professionals by providing valuable guidance and sharing their expertise.',
          }}
          img={{
            src: Message,
            alt: 'message-sharing',
          }}
        />
      ),
    },
    {
      id: 3,
      className:
        'border border-gray-600 h-[500px] overflow-hidden rounded-3xl md:col-span-2 xl:col-span-2 xl:row-span-2',
      col: {
        md: 2,
      },
      component: (
        <Feature
          reverse
          className='gap-4 lg:gap-6 [&>img]:w-fit'
          content={{
            label: 'Fostering Growth and Networking',
            paragraph:
              'This app facilitates mentorship programs, creating opportunities for professional growth and networking among users.',
          }}
          img={{
            src: Courses,
            alt: 'data-visualisation',
          }}
        />
      ),
    },
    {
      id: 4,
      className:
        'border border-gray-600 h-[500px] overflow-hidden rounded-3xl xl:col-span-3 xl:row-span-2',
      col: {
        xl: 3,
      },
      component: (
        <Feature
          className='gap-8 xl:gap-12'
          content={{
            label: 'Job Posting and Management',
            paragraph:
              'The app offers employers an intuitive interface for posting job openings and managing applicant profiles, simplifying the process of advertising and overseeing job listing.',
          }}
          img={{
            src: Jobs,
            alt: 'job-posting-and-management',
          }}
        />
      ),
    },
    {
      id: 5,
      className:
        'border border-gray-600 h-[500px] overflow-hidden rounded-3xl xl:col-span-3',
      col: {
        xl: 3,
      },
      component: (
        <Feature
          variant={FEATURES_VARIANT.IMAGE}
          img={{
            src: Communication,
            alt: 'job-posting-and-management',
          }}
        />
      ),
    },
  ];

  return features;
};
