import { LandingHero, MainHero } from './variants';

import { ReactComponent as AppleEmojiLight } from 'assets/avatar/apple-memoji-animation.svg';
import { ReactComponent as AppleEmojiDark } from 'assets/avatar/apple-memoji-animation--dark.svg';

export const firebaseCVLink =
  'https://firebasestorage.googleapis.com/v0/b/portfolio-database-2438e.appspot.com/o/Alex%20Zietara%20Nicholls.pdf?alt=media&token=bdd568e5-ce1b-4462-9872-27704dc58ae6';

export const HERO_VARIANTS = {
  PRIMARY: 'primary',
  LANDING: 'landing',
};

export const AppleEmoji = ({ isDarkMode }) => {
  return isDarkMode ? (
    <AppleEmojiDark className='w-full h-32 w-32 md:w-40 md:h-40' />
  ) : (
    <AppleEmojiLight className='w-full h-32 w-32 md:w-40 md:h-40' />
  );
};

export const setVariants = (controls, project) => {
  const variants = {
    [HERO_VARIANTS.PRIMARY]: () => <MainHero project={project} />,
    [HERO_VARIANTS.LANDING]: () => <LandingHero controls={controls} />,
  };

  variants['default'] = variants[HERO_VARIANTS.PRIMARY];

  return variants;
};
