import Paragraph from './base-components/Paragraph';
import Bullet from './base-components/Bullet-List';
import Number from './base-components/Number-List';

import { INFORMATION_VARIANTS } from '.';

export const renderInformationComponent = (variant, contents) => {
  let component = '';

  switch (variant) {
    case INFORMATION_VARIANTS.PARAGRAPH:
      component = <Paragraph contents={contents} />;
      break;
    case INFORMATION_VARIANTS.BULLET:
      component = <Bullet contents={contents} />;
      break;
    case INFORMATION_VARIANTS.NUMBER:
      component = <Number contents={contents} />;
      break;

    default:
      break;
  }

  return component;
};
