import * as Yup from 'yup';

export const formValues = {
  email: '',
  password: '',
};

export const validationSchema = Yup.object().shape({
  email: Yup.string().required('Email is required').email('Email is invalid'),
  password: Yup.string().required('Password is required'),
});

export const errorMessages = {
  'auth/user-not-found':
    'No user found with this email address. Please check and try again.',
  'auth/wrong-password': 'The password is incorrect. Please try again.',
  'auth/invalid-email':
    'The email address is not valid. Please enter a valid email address.',
  'auth/user-disabled':
    'This user has been disabled. Please contact support for help.',
  'auth/too-many-requests':
    'Too many unsuccessful login attempts. Please try again later.',
};
