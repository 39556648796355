import { useEffect, useMemo } from 'react';

import useAnimate from 'react-hooks/useAnimate';
import useComponent from 'react-hooks/useComponent';

import { setVariants } from './utils';
export { HERO_VARIANTS } from './utils';

function Hero({ variant, project, className }) {
  const { controls, containerRef } = useAnimate();
  const { component, renderVariants } = useComponent(variant);

  const variants = useMemo(() => {
    return setVariants(controls, project, containerRef);
  }, [controls, project, containerRef]);

  useEffect(() => {
    renderVariants(variants);
  }, [variants, renderVariants]);

  return (
    <div
      ref={containerRef}
      className={`hero flex flex-col gap-6 rounded-xl md:rounded-3xl md:gap-7 lg:gap-8 justify-center items-center ${className}`}
    >
      {component}
    </div>
  );
}

export default Hero;
