import FeatherIcon from 'feather-icons-react';
import ArrowUp from './Arrow-Up';
import Moon from './Moon';
import Sun from './Sun';

const handleIconVariant = (variant, stroke, props) => {
  let icon = '';

  switch (variant) {
    case 'arrow-up':
      icon = <ArrowUp stroke={stroke} {...props} />;
      break;
    case 'moon':
      icon = <Moon stroke={stroke} {...props} />;
      break;
    case 'sun':
      icon = <Sun stroke={stroke} {...props} />;
      break;
    default:
      break;
  }

  return icon;
};

function Icon({ icon, stroke, feather, ...props }) {
  const customIcon = handleIconVariant(icon, stroke, props);

  return <>{feather ? <FeatherIcon icon={icon} {...props} /> : customIcon}</>;
}

export default Icon;
