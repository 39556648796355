import { motion } from 'framer-motion';

import useAnimate, { fade } from 'react-hooks/useAnimate';
import { renderMediaComponent } from './utils';

export { MEDIA_VARIANTS } from './utils';

function Media({ variant, media, rounded, animated, className }) {
  const { containerRef, controls, animateVariant } = useAnimate(fade);

  const component = renderMediaComponent(
    variant,
    media?.img,
    media?.video,
    media?.caption
  );

  if (animated) {
    return (
      <motion.div
        ref={containerRef}
        className={`media-section lg:p-2 ${
          rounded ? 'rounded-xl md:rounded-2xl lg:rounded-3xl' : ''
        } ${className}`}
        initial='hidden'
        animate={controls}
        variants={animateVariant}
      >
        {component}
      </motion.div>
    );
  }

  return (
    <div
      className={`media-section ${
        rounded ? 'rounded-xl md:rounded-2xl lg:rounded-3xl' : ''
      } ${className}`}
    >
      {component}
    </div>
  );
}

export default Media;
