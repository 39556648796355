import { useState, useEffect } from 'react';
import AnimatedCursor from 'react-animated-cursor';

function Cursor() {
  const [isDesktop, setIsDesktop] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    // Initial check
    handleResize();

    // Listen for resize events
    window.addEventListener('resize', handleResize);

    // Clean up
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {isDesktop && (
        <AnimatedCursor
          showSystemCursor
          innerSize={10}
          innerScale={1}
          outerAlpha={0}
          innerStyle={{
            backgroundColor: 'var(--navy-900)',
          }}
          clickables={[ 
            'a',
            'input[type="text"]',
            'input[type="email"]',
            'input[type="number"]',
            'input[type="submit"]',
            'input[type="image"]',
            'label[for]',
            'select',
            'textarea',
            'button',
          ]}
        />
      )}
    </>
  );
}

export default Cursor;
