import { DropdownItem } from './utils';

function Dropdown({ links }) {
  return (
    <div className='dropdown flex flex-col p-1.5 pb-3 rounded-2xl bg-gray-50 border border-gray-600'>
      {links.map((link) => (
        <DropdownItem text={link.text} link={link.link} icon={link.icon} />
      ))}
    </div>
  );
}

export default Dropdown;
