import Image from './base-components/Image';
import Video from './base-components/Video';

export const MEDIA_VARIANTS = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
};

export const renderMediaComponent = (variant, img, video, caption, props) => {
  let component = '';

  switch (variant) {
    case MEDIA_VARIANTS.IMAGE:
      component = <Image img={img} caption={caption} {...props} />;
      break;
    case MEDIA_VARIANTS.VIDEO:
      component = <Video video={video} caption={caption} {...props} />;
      break;
    default:
      break;
  }

  return component;
};
