import React from 'react';
import { motion } from 'framer-motion';

function AnimateAction({ link, button, className, children, ...props }) {
  if (link) {
    return (
      <motion.a
        href={link.to}
        target={link.target ? link.target : null}
        className={className}
        {...props}
      >
        {children}
      </motion.a>
    );
  }

  return (
    <motion.button className={className} {...props}>
      {children}
    </motion.button>
  );
}

export default AnimateAction;
