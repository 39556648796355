import useTheme from 'react-hooks/useTheme';

import loginIllustrationDark from './../../assets/login_illustration--dark.mp4';
import loginIllustrationLight from './../../assets/login_illustration--light.mp4';

function Video({ videoKey, src }) {
  return (
    <video
      key={videoKey}
      className='w-full h-full object-cover object-center'
      autoPlay
      muted
    >
      <source src={src} />
    </video>
  );
}

export function LoginIllustration() {
  const { darkMode } = useTheme();

  return (
    <div className='login__illustration relative h-full w-full xl:max-w-[2800px] rounded-3xl hidden lg:flex'>
      <div className='project relative w-full h-full overflow-hidden rounded-[inherit]'>
        <div className='project_image absolute left-0 top-0 w-full h-full'>
          {darkMode ? (
            <Video videoKey='dark-mode-video' src={loginIllustrationDark} />
          ) : (
            <Video videoKey='light-mode-video' src={loginIllustrationLight} />
          )}
        </div>
      </div>
    </div>
  );
}

export const renderInputFields = (formik, showPassword, setShowPassword) => {
  const inputs = [
    {
      onClick: null,
      input: {
        name: 'email',
        type: 'text',
        value: formik.values.email,
        placeholder: 'Email',
        error:
          formik.errors.email && formik.touched.email
            ? formik.errors.email
            : null,
        icon: {
          isLeading: false,
          isTrailing: false,
        },
        onChange: formik.handleChange,
      },
    },
    {
      onClick: () => setShowPassword((prev) => !prev),
      input: {
        name: 'password',
        type: showPassword ? 'text' : 'password',
        value: formik.values.password,
        placeholder: 'Password',
        error:
          formik.errors.password && formik.touched.password
            ? formik.errors.password
            : null,
        icon: {
          feather: true,
          icon: {
            leading: showPassword ? 'eye-off' : 'eye',
          },
          stroke: {
            leading: formik.errors.password
              ? 'text-error-300'
              : 'text-brand-500',
          },
          isLeading: true,
        },
        onChange: formik.handleChange,
      },
    },
  ];

  return inputs;
};
