import { motion } from 'framer-motion';

import useAnimate, { reveal } from 'react-hooks/useAnimate';
import useTheme from 'react-hooks/useTheme';
import useComponent from 'react-hooks/useComponent';

import Headings, { HEADING_VARIANTS } from 'Shared-UI/Headings';
import Button, { BUTTON_VARIANTS } from 'Shared-UI/Button';

import { AppleEmoji, firebaseCVLink } from './utils';

export function MainHero({ project }) {
  return (
    <>
      <picture className='hero__image flex flex-col justify-center items-center h-[700px] md:h-fit'>
        <img
          src={project.img.src}
          alt={project.img.alt}
          srcSet={project.img.srcset}
        />
      </picture>
    </>
  );
}

export function LandingHero() {
  const { darkMode } = useTheme();
  const { toggleClasses } = useComponent();
  const { controls, containerRef, animateVariant } = useAnimate(reveal);

  return (
    <>
      <div className='hero__avatar flex flex-col justify-center items-center'>
        <AppleEmoji isDarkMode={darkMode} />
      </div>
      <div
        className='hero__content flex flex-col justify-center items-center gap-6 reveal-heading overflow-hidden'
        ref={containerRef}
      >
        <Headings
          className='hero__title [&>*]:text-center'
          heading={HEADING_VARIANTS.H1}
          title={
            <>
              <span
                className={toggleClasses('text-brand-500', 'text-brand-400')}
              >
                Designing beautiful interfaces
              </span>{' '}
              from simplicity to satisfaction
            </>
          }
        />
        <Headings
          className='hero__subtitle [&>*]:text-center'
          heading={HEADING_VARIANTS.H3}
          title='Alex Zietara Nicholls Portfolio'
        />
      </div>
      <div className='hero__actions flex flex-col justify-center items-center gap-4 reveal-heading overflow-hidden'>
        <motion.div
          animate={controls}
          initial='hidden'
          variants={animateVariant}
          className='link-wrapper flex flex-row jusitify-center items-center h-[56px]'
        >
          <Button
            variant={BUTTON_VARIANTS.PRIMARY}
            text='Resume'
            link={{ to: firebaseCVLink, target: '_blank' }}
            icon={{
              isLeading: true,
              icon: {
                leading: 'arrow-up',
              },
              stroke: {
                leading: 'var(--gray-50)',
              },
              size: '8',
            }}
          />
        </motion.div>
      </div>
    </>
  );
}
