function Sun({ isEnabled }) {
  return (
    <>
      <svg
        width='23'
        height='23'
        viewBox='0 0 23 23'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M11.3828 4V5.19721M18.7656 11.3828H17.5684M11.3828 17.5684V18.7656M5.19721 11.3828H4M14.6172 11.3828C14.6172 13.1691 13.1691 14.6172 11.3828 14.6172C9.59646 14.6172 8.14835 13.1691 8.14835 11.3828C8.14835 9.59646 9.59646 8.14835 11.3828 8.14835C13.1691 8.14835 14.6172 9.59646 14.6172 11.3828Z'
          fill={isEnabled ? 'none' : '#FCFCFC'}
          stroke={isEnabled ? '#9DA9B4' : '#FCFCFC'}
          strokeWidth='1.25609'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <circle
          cx='15.7736'
          cy='7.23156'
          r='0.596644'
          fill={isEnabled ? '#9DA9B4' : '#FCFCFC'}
          stroke={isEnabled ? '#9DA9B4' : '#FCFCFC'}
          strokeWidth='0.0628046'
        />
        <circle
          cx='15.7736'
          cy='15.7101'
          r='0.596644'
          fill={isEnabled ? '#9DA9B4' : '#FCFCFC'}
          stroke={isEnabled ? '#9DA9B4' : '#FCFCFC'}
          strokeWidth='0.0628046'
        />
        <circle
          cx='7.23156'
          cy='7.23156'
          r='0.596644'
          fill={isEnabled ? '#9DA9B4' : '#FCFCFC'}
          stroke={isEnabled ? '#9DA9B4' : '#FCFCFC'}
          strokeWidth='0.0628046'
        />
        <circle
          cx='7.23156'
          cy='15.7101'
          r='0.596644'
          fill={isEnabled ? '#9DA9B4' : '#FCFCFC'}
          stroke={isEnabled ? '#9DA9B4' : '#FCFCFC'}
          strokeWidth='0.0628046'
        />
      </svg>
    </>
  );
}

export default Sun;
