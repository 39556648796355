import { Badge, BadgeDot, BadgeMessage } from './variants';

export const BADGE_VARIANTS = {
  BADGE: 'badge',
  DOT: 'badge-dot',
  MESSAGE: 'badge-message',
};

export const setVariants = (label, icon, message, toggle) => {
  const variants = {
    [BADGE_VARIANTS.BADGE]: () => <Badge label={label} />,
    [BADGE_VARIANTS.DOT]: () => <BadgeDot label={label} icon={icon} />,
    [BADGE_VARIANTS.MESSAGE]: () => (
      <BadgeMessage label={label} icon={icon} message={message} />
    ),
  };

  variants['default'] = variants[BADGE_VARIANTS.BADGE];

  return variants;
};
