import Information from './Information';
import Scenario from './Scenario';
import Aside from './Aside';
import Heading from './Heading';
import Media from './Media';

export const SECTION_VARIANTS = {
  ASIDE: 'ASIDE',
  HEADING: 'HEADING',
  INFORMATION: 'INFORMATION',
  MEDIA: 'MEDIA',
  SCENARIO: 'SCENARIO',
};

export const setVariants = (heading, aside, information, scenario, media) => {
  const variants = {
    [SECTION_VARIANTS.HEADING]: () => (
      <Heading
        showSubtitle={heading?.showSubtitle}
        headings={heading?.headings}
        className={heading?.className}
      />
    ),
    [SECTION_VARIANTS.MEDIA]: () => (
      <Media
        variant={media?.variant}
        media={media?.media}
        animated={media?.animated}
        className={media?.className}
        rounded={media?.rounded}
      />
    ),
    [SECTION_VARIANTS.INFORMATION]: () => (
      <Information
        variant={information?.variant}
        title={information?.title}
        contents={information?.contents}
      />
    ),
    [SECTION_VARIANTS.SCENARIO]: () => <Scenario content={scenario?.content} />,
    [SECTION_VARIANTS.ASIDE]: () => (
      <Aside
        content={aside?.content}
        img={aside?.img}
        reverse={aside?.reverse}
      />
    ),
  };

  variants['default'] = variants[SECTION_VARIANTS.HEADING];

  return variants;
};
