import Headings, { HEADING_VARIANTS } from 'Shared-UI/Headings';
import { renderInformationComponent } from './utils';

export const INFORMATION_VARIANTS = {
  PARAGRAPH: 'PARAGRAPH',
  BULLET: 'BULLET',
  NUMBER: 'NUMBER',
};

function Information({ variant, contents }) {
  const component = renderInformationComponent(variant, contents);

  return (
    <div className='info-section flex flex-col gap-4 lg:flex-row lg:gap-5'>
      <div className='info_title flex flex-col w-full lg:w-48'>
        <Headings heading={HEADING_VARIANTS.H3} title={contents.title} />
      </div>
      <div className='info_wrapper flex flex-col w-full'>
        <div className='info_content'>{component}</div>
      </div>
    </div>
  );
}

export default Information;
