import { motion } from 'framer-motion';
import Icon from 'Icons';
import useComponent from 'react-hooks/useComponent';
import Action from './Action';
import AnimateAction from './AnimateAction';

const hover = (prop) =>
  `hover:transition hover:ease-in hover:delay-150 ${prop}`;
const focus = (prop) => ` focus:outline-none ${prop ? prop : ''}`;
const disabled = (prop) => `disabled:shadow-none ${prop ? prop : ''}`;
const before = (prop) =>
  `${
    prop
      ? prop
      : 'before:content-[""] before:absolute before:block before:w-full before:scale-x-0 before:transition-transform before:duration-500 before:ease before:origin-top-right before:h-[2px] before:bottom-[-2px] before:left-0 '
  }`;

const buttonStateClasses = {
  primary: {
    light: `bg-brand-500 shadow-btn-primary--light  ${hover(
      'hover:bg-brand-600 hover:shadow-btn-primary__hover--light'
    )} ${focus('focus:shadow-btn-primary__focus--light')} ${disabled(
      'disabled:bg-brand-300'
    )}`,

    dark: `bg-brand-400 ${hover(
      'hover:bg-brand-300 hover:shadow-btn-primary__hover--dark'
    )} ${focus('focus:shadow-btn-primary__focus--dark')} ${disabled(
      'disabled:bg-brand-300'
    )}`,
  },
  secondary: {
    light: `border border-gray-600 text-black ${hover(
      'hover:shadow-btn-secondary__hover--light  '
    )} ${focus('focus:shadow-btn-primary__focus--light')}`,

    dark: `border border-navy-600 text-white ${hover(
      'hover:bg-navy-700'
    )} ${focus('hover:shadow-btn-secondary__hover--dark')}`,
  },
  icon: {
    light: `bg-gray-50 border border-[#f6f7f9] text-black ${hover(
      'hover:shadow-btn-icon__hover--light'
    )} ${focus('focus:shadow-btn-icon__hover--light')}`,

    dark: `bg-navy-900 text-white border border-[#171e2b] ${hover(
      'hover:bg-navy-700 shadow-btn-icon__hover--dark'
    )} ${focus('focus:shadow-btn-icon__hover--dark')}`,
  },
  link: {
    light: `text-brand-500 ${before(`before:bg-brand-500`)}`,
    dark: `text-brand-600 ${before(`before:bg-brand-600`)}`,
  },
};

export function ButtonPrimary({ link, icon, text, className, ...props }) {
  const { toggleClasses } = useComponent();

  return (
    <Action
      link={link}
      className={`button button__secondary flex flex-row items-center gap-1.5 py-1.5 px-4 rounded-full ${
        className ? className : ''
      } ${toggleClasses(
        buttonStateClasses.primary.light,
        buttonStateClasses.primary.dark
      )}`}
    >
      <>
        {icon.isTrailing ? (
          <Icon
            feather={icon.feather}
            stroke={icon.stroke.trailing}
            icon={`${icon.icon.trailing ? icon.icon.trailing : 'plus'}`}
          />
        ) : null}

        <span className='font-semibold text-white'>{text}</span>

        {icon.isLeading ? (
          <Icon
            feather={icon.feather}
            stroke={icon.stroke.leading}
            icon={`${icon.icon.leading ? icon.icon.leading : 'plus'}`}
          />
        ) : null}
      </>
    </Action>
  );
}

export function ButtonSecondary({ link, icon, text, ...props }) {
  const { toggleClasses } = useComponent();

  return (
    <Action
      link={link}
      button={{ text: text }}
      className={`button button__secondary flex flex-row items-center gap-1.5 py-1.5 px-4 rounded-full ${toggleClasses(
        buttonStateClasses.secondary.light,
        buttonStateClasses.secondary.dark
      )}`}
      {...props}
    >
      <>
        {icon.isTrailing ? (
          <Icon
            feather={icon.feather}
            stroke={icon.stroke.trailing}
            icon={`${icon.icon.trailing ? icon.icon.trailing : 'plus'}`}
          />
        ) : null}

        <span className='font-semibold'>{text}</span>

        {icon.isLeading ? (
          <Icon
            feather={icon.feather}
            stroke={icon.stroke.leading}
            icon={`${icon.icon.leading ? icon.icon.leading : 'plus'}`}
          />
        ) : null}
      </>
    </Action>
  );
}

export function ButtonIcon({ link, text, hovered, defaultWidth }) {
  const { toggleClasses, toggle } = useComponent();

  return (
    <AnimateAction
      link={link}
      initial={{ width: defaultWidth }}
      animate={{ width: hovered ? defaultWidth : 40 }}
      className={`button button__icon absolute flex items-center overflow-hidden h-7 rounded-full bottom-1.5 left-1.5 ${toggleClasses(
        buttonStateClasses.icon.light,
        buttonStateClasses.icon.dark
      )}`}
    >
      <motion.span
        className={toggleClasses(
          'ml-4 mr-6 mt-0 mb-0 text-base font-medium whitespace-nowrap',
          'text-white'
        )}
        initial={{ opacity: 0, x: -12 }}
        animate={{
          opacity: hovered ? 1 : 0,
          x: hovered ? -12 : -12,
        }}
      >
        {text}
      </motion.span>
      <div className='absolute icon-wrapper w-[32px] h-[32px] flex items-center justify-center right-[4px]'>
        <Icon icon='arrow-up' stroke={toggle('#090B11', '#E0E1E3 ')} />
      </div>
    </AnimateAction>
  );
}

export function Link({ link, icon, text, className, ...props }) {
  const { toggleClasses } = useComponent();

  return (
    <Action
      link={link}
      button={{ text: text }}
      className={`link relative hover:before:scale-x-100 ${toggleClasses(
        buttonStateClasses.link.light,
        buttonStateClasses.link.dark
      )}`}
    >
      <span className={`leading-9 font-medium ${className ? className : ''}`}>
        {text}
      </span>

      {icon.isLeading ? (
        <Icon
          feather={icon.feather}
          stroke={icon.stroke.leading}
          icon={`${icon.icon.leading ? icon.icon.leading : 'plus'}`}
        />
      ) : null}
    </Action>
  );
}
