import useComponent from 'react-hooks/useComponent';
import useAnimate, { fade } from 'react-hooks/useAnimate';

import Container from 'Grid/Container';
import Layout, { LAYOUT_VARIANTS } from 'Layout';
import withProtected from 'hoc/withProtected';

import { HEADING_VARIANTS } from 'Shared-UI/Headings';

import Section, {
  SECTION_VARIANTS,
  MEDIA_VARIANTS,
  INFORMATION_VARIANTS,
} from 'UI/Section';
import Bento from 'UI/Bento';

import SteadyHero from 'assets/Steady_Paycheck/hero_wallpaper.png';
import Persona from 'assets/Steady_Paycheck/persona.png';
import Prototype from 'assets/Steady_Paycheck/Prototype.mp4';
import UserFlows from 'assets/Steady_Paycheck/userflows.webm';
import Wallpaper from 'assets/Steady_Paycheck/wallpaper.png';
import Wireframes from 'assets/Steady_Paycheck/wireframes.png';

import UserFlowsDark from 'assets/Steady_Paycheck/userflows--dark.webm';
import PersonaDark from 'assets/Steady_Paycheck/persona--dark.png';

import { setFeatures } from './features';
import { steadypaychecktags } from '../..';

const facts = [
  {
    label: 'Role',
    role: 'UX/UI Designer',
  },
  {
    label: 'Duration',
    role: 'UX/UI Designer',
  },
  {
    label: '8 Weeks',
    role: 'Job Recruitment University Project',
  },
];

const links = [
  {
    id: 0,
    url: 'https://www.figma.com/proto/RnwuNrXcNmfHBFrqXgUqSx/Steady-Paycheck-Agency%3A-Prototype?page-id=100%3A13933&node-id=142-21204&viewport=2287%2C-4467%2C0.48&t=rnSHNJEqM8WuyvGZ-1&scaling=scale-down&content-scaling=fixed&starting-point-node-id=105%3A14159&show-proto-sidebar=1',
    title: 'Launch Demo',
  },
];

function SteadyPayCheck() {
  const { toggle, toggleClasses } = useComponent();
  const { containerRef, controls, variant: fadeVariants } = useAnimate(fade);

  const animate = { controls, fadeVariants };

  const features = setFeatures(toggle, containerRef, animate, toggleClasses);

  return (
    <Layout
      variant={LAYOUT_VARIANTS.PROJECT}
      meta={{
        title: 'Steady Paycheck: Revolutionsing Job Recruitment App',
        tags: steadypaychecktags,
      }}
      project={{
        name: 'spa',
      }}
      hero={{
        title: 'Revolutionising Job Recruitment App',
        subtitle: 'Steady Paycheck',
        project: {
          img: { src: SteadyHero, alt: 'steady-paycheck-wallpaper' },
        },
        className: 'bg-gray-400',
      }}
      scenario={{
        content: {
          paragraph:
            'At Steady Paycheck Agency, our mission is to transform job recruitment through a user-friendly app that bridges the gap between job seekers and employers. Our goal is to create an efficient platform that nurtures connections, fosters career development, and ensures long-term job satisfaction.',
          facts: facts,
          links: links,
        },
      }}
    >
      <Container full>
        <Section
          variant={SECTION_VARIANTS.MEDIA}
          media={{
            variant: MEDIA_VARIANTS.IMAGE,
            media: {
              img: {
                src: Wallpaper,
                alt: 'UI Screens - Steady Paycheck',
              },
            },
            className: `overflow-hidden md:h-[43-rem] xl:h-[75rem] ${toggleClasses(
              'bg-gray-400',
              'bg-navy-500'
            )}`,
          }}
        />
      </Container>

      <Container>
        <div className='spa spa__discover flex flex-col gap-7 lg:gap-9'>
          <Section
            variant={SECTION_VARIANTS.HEADING}
            heading={{
              headings: {
                heading: HEADING_VARIANTS.H2,
                title: 'Uncovering Insights into Users & Market Research',
                subtitle: 'Discover',
              },
              showSubtitle: true,
              className:
                'text-left md:[&>*]:text-center [&>*]:xl:w-96 [&>*]:xl:m-auto',
            }}
          />

          <Section
            variant={SECTION_VARIANTS.INFORMATION}
            information={{
              variant: INFORMATION_VARIANTS.PARAGRAPH,

              contents: {
                title: 'Research & Findings',
                paragraph:
                  'Our needs analysis has revealed crucial insights into user preferences and requirements, informing our design decisions. Weve identified areas for improvement and usability enhancements by studying platforms like Indeed, Jobsites, and Monster. Indeeds chatbot employs common fate principles for seamless pop-up messages. Jobsites lacks clear visual hierarchy on job pages, affecting user navigation. All platforms effectively group related elements using proximity. Users on Jobsite lack feedback, requiring navigation to external sites. Monsters search filters could benefit from improved visibility. Indeed follows a task-centered design approach, while others prioritize user-centered design.',
              },
            }}
          />
        </div>
      </Container>

      <Container>
        <div className='spa spa__define flex flex-col gap-7 lg:gap-9'>
          <Section
            variant={SECTION_VARIANTS.HEADING}
            heading={{
              headings: {
                heading: HEADING_VARIANTS.H2,
                title: 'Defining the Challenge',
                subtitle: 'Define',
              },
              showSubtitle: true,
              className: '[&>*]:text-center [&>*]:xl:w-96 [&>*]:xl:m-auto',
            }}
          />
          <Section
            variant={SECTION_VARIANTS.INFORMATION}
            information={{
              variant: INFORMATION_VARIANTS.PARAGRAPH,

              contents: {
                title: 'Personas',
                paragraph:
                  'Our persona map out the journey users take to complete specific tasks, enhancing the apps usability.',
              },
            }}
          />
          <Section
            variant={SECTION_VARIANTS.MEDIA}
            media={{
              variant: MEDIA_VARIANTS.IMAGE,
              media: {
                img: {
                  src: toggle(Persona, PersonaDark),
                  alt: 'Andrew Barn Smith - User Persona',
                },
              },
              className: `overflow-hidden rounded-3xl bg-gray-400 p-10 [&>*]:w-2/3 ${toggleClasses(
                'bg-gray-400',
                'bg-navy-500'
              )}`,
              animated: true,
            }}
          />
          <Section
            variant={SECTION_VARIANTS.INFORMATION}
            information={{
              variant: INFORMATION_VARIANTS.PARAGRAPH,
              contents: {
                title: 'Problem Statement',
                paragraph:
                  'The current job search landscape is fragmented and challenging for job seekers, employers, and mentors. To address these pain points, we aim to create a user-friendly, mobile-responsive platform that streamlines the job search process and facilitates mentorship connections, fostering career growth.',
              },
            }}
          />
        </div>
      </Container>
      <Container>
        <div className='spa spa__design flex flex-col gap-7 lg:gap-9'>
          <Section
            variant={SECTION_VARIANTS.HEADING}
            heading={{
              headings: {
                heading: HEADING_VARIANTS.H2,
                title: 'Crafting the experience of the apps designs',
                subtitle: 'Design',
              },
              showSubtitle: true,
              className:
                'text-left md:[&>*]:text-center[&>*]:xl:w-96 [&>*]:xl:m-auto',
            }}
          />
          <Section
            variant={SECTION_VARIANTS.INFORMATION}
            information={{
              variant: INFORMATION_VARIANTS.PARAGRAPH,
              contents: {
                title: 'User Flows',
                paragraph:
                  'Our user flows map out the journey users take to complete specific tasks, enhancing the apps usability.',
              },
            }}
          />
          <Section
            variant={SECTION_VARIANTS.MEDIA}
            media={{
              variant: MEDIA_VARIANTS.VIDEO,
              media: {
                video: {
                  key: toggle('light-mode-video', 'dark-mode-video'),
                  type: {
                    type: 'video/mp4',
                  },
                  src: {
                    source: toggle(UserFlows, UserFlowsDark),
                    source2: toggle(UserFlows, UserFlowsDark),
                  },
                  alt: 'Andrew Barn Smith - User Persona',
                },
              },
              className: `p-10 rounded-3xl ${toggleClasses(
                'bg-gray-400',
                'bg-navy-500'
              )}`,
              animated: true,
            }}
          />
        </div>
      </Container>
      <Container>
        <Section
          variant={SECTION_VARIANTS.INFORMATION}
          information={{
            variant: INFORMATION_VARIANTS.PARAGRAPH,
            contents: {
              title: 'Wireframes',
              paragraph:
                'The wireframes produced for the job recruitment app provide a visual representation of the apps layout and functionality, allowing for the testing and refinement of the user flow and overall user experience.',
            },
          }}
        />
      </Container>
      <Container full>
        <Section
          variant={SECTION_VARIANTS.MEDIA}
          media={{
            variant: MEDIA_VARIANTS.IMAGE,
            media: {
              img: {
                src: Wireframes,
                alt: 'View Showers - User Flow',
              },
            },
            className: `overflow-hidden md:h-[43-rem]  ${toggleClasses(
              'bg-gray-400',
              'bg-navy-500'
            )}`,
          }}
        />
      </Container>
      <Container>
        <div className='spa spa__design flex flex-col gap-7 lg:gap-9'>
          <Section
            variant={SECTION_VARIANTS.INFORMATION}
            information={{
              variant: INFORMATION_VARIANTS.PARAGRAPH,
              contents: {
                title: 'High Fidelity',
                paragraph:
                  'High-fidelity designs showcase the apps interface. It allows job seekers to search and apply for jobs. Supply mentors with tools for guidance and program facilitation. Gives employers the ability to seamlessly post jobs, manage account profiles, and easily communicate through out the hiring process.',
              },
            }}
          />

          <div className='grid gap-4 w-full md:grid-cols-4 xl:grid-cols-6'>
            {features.map((feature) => (
              <Bento key={feature.id} col={feature.col} className={feature.className}>
                {feature.component}
              </Bento>
            ))}
          </div>
        </div>
      </Container>
      <Container>
        <div className='spa spa__develop flex flex-col gap-7 lg:gap-9'>
          <Section
            variant={SECTION_VARIANTS.HEADING}
            heading={{
              headings: {
                heading: HEADING_VARIANTS.H2,
                title: 'Crafting a Clickable Prototype',
                subtitle: 'Develop',
              },
              showSubtitle: true,
              className:
                'text-left md:[&>*]:text-center [&>*]:xl:w-96 [&>*]:xl:m-auto',
            }}
          />
          <Section
            variant={SECTION_VARIANTS.INFORMATION}
            information={{
              variant: INFORMATION_VARIANTS.PARAGRAPH,
              contents: {
                title: 'Prototype',
                paragraph:
                  'A two-minute clickable prototype not only improves job-seeking efficiency and user-friendliness but also offers a hands-on preview of the apps intuitive features and functionality.',
              },
            }}
          />
          <Section
            variant={SECTION_VARIANTS.MEDIA}
            media={{
              variant: MEDIA_VARIANTS.VIDEO,
              media: {
                video: {
                  type: {
                    type: 'video/mp4',
                  },
                  src: {
                    source: Prototype,
                  },
                  alt: 'Steady Paycheck - Clickable Prototype',
                },
              },
              className: `w-full [&>*]:rounded-3xl ${toggleClasses(
                '[&>*]:bg-gray-400',
                ''
              )}`,
              animated: true,
            }}
          />
        </div>
      </Container>
    </Layout>
  );
}

export default withProtected(SteadyPayCheck);
