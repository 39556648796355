import { useRef, useEffect } from 'react';
import { useAnimation, useInView } from 'framer-motion';

export const reveal = {
  hidden: {
    opacity: 0,
    y: '100%',
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1.5,
      ease: [0.77, 0, 0.175, 1],
      delay: 0.5,
    },
  },
};

export const fade = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration: 1, ease: 'easeIn' },
  },
};

function useAnimate(variant) {
  const containerRef = useRef(null);
  const controls = useAnimation();
  const isInView = useInView(containerRef, { once: true });

  useEffect(() => {
    if (isInView) {
      controls.start('visible');
    }
  });

  return {
    controls,
    containerRef,
    variant,
  };
}

export default useAnimate;
