import FormControl from 'Shared-UI/FormControl';
import { ServerMessage } from './utils';
import Button, { BUTTON_VARIANTS } from 'Shared-UI/Button';

function Form({ handleSubmit, inputs, error, button }) {
  return (
    <form
      className='form flex flex-col items-center w-full gap-6 md:w-48'
      onSubmit={handleSubmit}
      autoComplete='off'
    >
      {error && <ServerMessage error={error} />}
      <div className='form__inputs w-full flex flex-col gap-3'>
        {inputs.map(({ onClick, input }, index) => (
          <FormControl key={index} onClick={onClick} input={input} />
        ))}
      </div>

      <Button
        type='submit'
        variant={BUTTON_VARIANTS.PRIMARY}
        className='w-full flex flex-col justify-center py-2'
        disabled={button.disabled}
        text={button.text}
        icon={button.icon}
      />
    </form>
  );
}

export default Form;
