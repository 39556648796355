import Container from 'Grid/Container';
import { Meta } from 'Pages';

import Switch from 'Shared-UI/Switch';
import { HEADING_VARIANTS } from 'Shared-UI/Headings';

import Footer from 'UI/Footer';
import Header from 'UI/Header';
import Hero, { HERO_VARIANTS } from 'UI/Hero';
import Section, { SECTION_VARIANTS } from 'UI/Section';

function Project({ meta, hero, scenario, project, children }) {
  return (
    <>
      <Meta title={meta.title} tags={meta.tags} />

      <div className={`${project.name}`}>
        <Container>
          <Header />
        </Container>
        <Container full className='project__wallpaper px-2 md:px-6 lg:px-7'>
          <Hero
            variant={HERO_VARIANTS.PRIMARY}
            className={hero.className}
            project={hero.project}
          />
        </Container>
      </div>
      <Container>
        <div
          className={`${project.name} ${project.name}__hero flex flex-col gap-4 md:gap-6 lg:gap-11`}
        >
          <Section
            variant={SECTION_VARIANTS.HEADING}
            heading={{
              headings: {
                heading: HEADING_VARIANTS.H1,
                title: hero.title,
                subtitle: hero.subtitle,
              },
              showSubtitle: true,
            }}
          />

          <Section
            variant={SECTION_VARIANTS.SCENARIO}
            scenario={{
              content: scenario.content,
            }}
          />
        </div>
      </Container>
      <>{children}</>
      <Container>
        <Footer />
      </Container>
    </>
  );
}

export default Project;
