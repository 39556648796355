import useComponent from 'react-hooks/useComponent';

import Badge, { BADGE_VARIANTS } from 'Shared-UI/Badge';
import Button, { BUTTON_VARIANTS } from 'Shared-UI/Button';

export function CaseStudy({
  render,
  project,
  hovered,
  onMouseEnter,
  onMouseLeave,
}) {
  return (
    <div
      className={`project relative h-full overflow-hidden rounded-[inherit] `}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className='project_image absolute left-0 top-0 w-full h-full'>
        {render}
      </div>
      {project.button.text && (
        <Button
          variant={BUTTON_VARIANTS.ICON}
          link={project.button.link}
          text={project.button.text}
          hovered={hovered}
          defaultWidth={project.button.width}
        />
      )}
    </div>
  );
}

export function CaseStudyInProgress({ badge, project }) {
  const { toggle } = useComponent();

  return (
    <div className='project flex flex-col justify-center items-center h-full px-6 pt-9 pb-8'>
      <div className='project_image flex flex-col justify-center items-center h-full'>
        <picture>
          <img
            className='w-full h-full object-cover object-center'
            src={project.media.src}
            alt={project.media.alt}
          />
        </picture>
      </div>

      <Badge
        variant={BADGE_VARIANTS.MESSAGE}
        label={badge.label}
        message={badge.message}
        icon={{
          feather: true,
          icon: 'circle',
          fill: toggle('var(--navy-400)', 'var(--gray-50)'),
        }}
      />
    </div>
  );
}
