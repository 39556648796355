import Main from './Main';
import Project from './Project';

export const LAYOUT_VARIANTS = {
  PRIMARY: 'PRIMARY',
  PROJECT: 'PROJECT',
};

function Layout({ variant, meta, hero, project, scenario, children }) {
  let component = '';

  switch (variant) {
    case LAYOUT_VARIANTS.PRIMARY:
      component = <Main children={children} hero={hero} meta={meta} />;
      break;
    case LAYOUT_VARIANTS.PROJECT:
      component = (
        <Project
          children={children}
          hero={hero}
          project={project}
          scenario={scenario}
          meta={meta}
        />
      );
      break;
    default:
      component = <Main children={children} />;
  }

  return <>{component}</>;
}

export default Layout;
