import { H1, H2, H3, H4, H5 } from './headings';

export const HEADING_VARIANTS = {
  H1: 'H1',
  H2: 'H2',
  H3: 'H3',
  H4: 'H4',
  H5: 'H5',
};

export const setVariants = (controls, reveal, title, className, toggle) => {
  const headings = {
    [HEADING_VARIANTS.H1]: () => (
      <H1
        animate={{ controls: controls, variants: reveal }}
        title={title}
        className={className}
        toggle={toggle}
      />
    ),
    [HEADING_VARIANTS.H2]: () => (
      <H2
        animate={{ controls: controls, variants: reveal }}
        title={title}
        className={className}
        toggle={toggle}
      />
    ),
    [HEADING_VARIANTS.H3]: () => (
      <H3
        animate={{ controls: controls, variants: reveal }}
        title={title}
        className={className}
        toggle={toggle}
      />
    ),
    [HEADING_VARIANTS.H4]: () => (
      <H4
        animate={{ controls: controls, variants: reveal }}
        title={title}
        className={className}
        toggle={toggle}
      />
    ),
    [HEADING_VARIANTS.H5]: () => (
      <H5
        animate={{ controls: controls, variants: reveal }}
        title={title}
        className={className}
        toggle={toggle}
      />
    ),
  };

  headings['default'] = headings[HEADING_VARIANTS.H1];

  return headings;
};
