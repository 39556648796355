import { useContext, useEffect } from 'react';
import { ThemeContext } from 'react-context/ThemeProvider';

function useTheme() {
  const theme = useContext(ThemeContext);

  if (!theme) {
    throw new Error('Theme context must be used inside the Themeprovider');
  }

  useEffect(() => {
    const root = document.querySelector('body');
    root.className = theme.darkMode
      ? 'bg-navy-900 text-gray-50 transition-background duration-2000 scrollbar--dark'
      : 'bg-gray-50 text-navy-700 transition-background duration-2000 scrollbar--light';

    return () => {
      root.className = '';
    };
  }, [theme.darkMode]);

  return theme;
}

export default useTheme;
