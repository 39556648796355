import React from 'react';
import Input from 'Shared-UI/Input';

function FormControl({ input, onClick }) {
  return (
    <div className='form-control w-full flex flex-col gap-1.5'>
      <Input
        type={input.type}
        name={input.name}
        placeholder={input.placeholder}
        onChange={input.onChange}
        value={input.value}
        icon={input.icon}
        err={input.error}
        leadingClick={onClick}
      />
      <p className={`pl-2 text-base text-error-300`}>{input.error}</p>
    </div>
  );
}

export default FormControl;
