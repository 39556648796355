import { useMemo, useEffect } from 'react';
import useComponent from 'react-hooks/useComponent';
import { setVariants } from './utils';
export { BADGE_VARIANTS } from './utils';

function Badge({ variant, label, icon, message }) {
  const { component, renderVariants, toggleClasses } = useComponent(variant);

  const variants = useMemo(
    () => setVariants(label, icon, message),
    [label, icon, message]
  );

  useEffect(() => {
    renderVariants(variants);
  }, [variants, renderVariants]);

  return (
    <div
      className={`badge rounded-full flex flex-row justify-center items-center gap-2 px-4 py-1.5 ${toggleClasses(
        'bg-grayblue-300',
        'bg-navy-900'
      )}`}
    >
      {component}
    </div>
  );
}

export default Badge;
