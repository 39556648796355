import { useRef, useEffect } from 'react';
import { motion, useInView, useAnimation } from 'framer-motion';

import useComponent from 'react-hooks/useComponent';
import useAnimate, { fade } from 'react-hooks/useAnimate';

const channelVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 1.5,
      ease: [0.77, 0, 0.175, 1],
      delay: 0.5,
    },
  },
};

function Channel({ icon, channel, link }) {
  const { toggleClasses } = useComponent();
  const { containerRef, controls, variant: channelVariants } = useAnimate(fade);

  return (
    <div
      ref={containerRef}
      className={`channel__wrapper relative h-fit w-full `}
    >
      <motion.div
        animate={controls}
        initial='hidden'
        variants={channelVariants}
        tabIndex='1'
        className={`channel p-4 rounded-xl md:py-7 md:rounded-2xl border w-full lg:rounded-3xl ${toggleClasses(
          'border-gray-600 bg-gray-500 outline-none hover:border-brand-100 focus:border-brand-100',
          'border-navy-600 bg-navy-500 outline-none hover:border-brand-400 focus:border-brand-400'
        )}`}
      >
        <a
          target='_blank'
          href={link}
          className='focus:outline-none'
          rel='noreferrer'
        >
          <span className='channel__container w-full flex flex-row justify-center items-center gap-1'>
            <div
              className={`channel__icon text-base font-medium ${toggleClasses(
                'text-black',
                'text-white'
              )}`}
            >
              {icon}
            </div>
            <div
              className={`channel__title text-base font-medium ${toggleClasses(
                'text-black',
                'text-white'
              )}`}
            >
              {channel}
            </div>
          </span>
        </a>
      </motion.div>
    </div>
  );
}

export default Channel;
