import { motion } from 'framer-motion';

import useAnimate, { reveal } from 'react-hooks/useAnimate';
import useComponent from 'react-hooks/useComponent';

import Headings, { HEADING_VARIANTS } from 'Shared-UI/Headings';

function Main({ title, paragraph }) {
  const { toggleClasses } = useComponent();
  const { containerRef, controls, variant: infoVariants } = useAnimate(reveal);

  return (
    <div className='aside_main flex flex-col gap-5 xl:gap-9 xl:w-full'>
      <div
        className='aside_content overflow-hidden flex flex-col gap-6 xl:gap-6'
        ref={containerRef}
      >
        <Headings heading={HEADING_VARIANTS.H3} title={title} />
        <motion.p
          variants={infoVariants}
          animate={controls}
          initial='hidden'
          className={`text-lg leading-9 ${toggleClasses(
            'text-navy-700',
            'text-gray-700'
          )}`}
        >
          {paragraph}
        </motion.p>
      </div>
    </div>
  );
}

export default Main;
