import Icon from 'Icons';
import useComponent from 'react-hooks/useComponent';

const hover = (hover) => (hover ? hover : '');
const focus = (focus) => (focus ? focus : '');
const error = (error) => (error ? 'border-error-100' : 'border-gray-600');

const inputClasses = {
  light: `border-gray-600 ${hover('hover:border-brand-500')} ${focus(
    'focus:border-brand-500'
  )}`,

  dark: `border-navy-500 ${hover('hover:border-brand-400')} ${focus(
    'focus:border-brand-400'
  )}`,
};

function Input({ icon, err, leadingClick, ...props }) {
  const { toggleClasses } = useComponent();

  return (
    <div
      className={`input p-2 rounded-2xl flex flex-row items-center border border-solid ${error(
        err
      )} ${toggleClasses(inputClasses.light, inputClasses.dark)}`}
    >
      {icon.isTrailing ? (
        <Icon
          feather={icon.feather}
          stroke={icon.stroke.trailing}
          icon={`${icon.icon.trailing ? icon.icon.trailing : 'plus'}`}
          size='16'
        />
      ) : null}
      <input
        className={`input input__base w-full bg-transparent outline-0 ${focus(
          'focus:outline-none'
        )} ${toggleClasses('placeholder-navy-300', 'placeholder-gray-200')} `}
        autoComplete='new-password'
        {...props}
      />
      {icon.isLeading ? (
        <Icon
          onClick={leadingClick}
          feather={icon.feather}
          stroke={icon.stroke.leading}
          icon={`${icon.icon.leading ? icon.icon.leading : 'plus'}`}
          size='16'
        />
      ) : null}
    </div>
  );
}

export default Input;
