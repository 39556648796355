import { useMemo, useEffect } from 'react';

import { CaseStudy, CaseStudyInProgress } from './variants';

export const PROJECT_VARIANTS = {
  CASE_STUDY: 'case-study',
  IN_PROGRESS: 'in-progess',
};

function Project({
  variant,
  project,
  badge,
  render,
  hovered,
  onMouseEnter,
  onMouseLeave,
}) {
  if (variant === PROJECT_VARIANTS.IN_PROGRESS) {
    return <CaseStudyInProgress badge={badge} project={project} />;
  }

  return (
    <CaseStudy
      project={project}
      render={render}
      hovered={hovered}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  );
}

export default Project;
