function Image({ img, caption }) {
  return (
    <>
      <picture>
        <img className='w-full' src={img.src} alt={img.alt} />
      </picture>
      {caption && (
        <span className='text-gray-900 italic font-regular text-sm md:text-base'>
          {caption}
        </span>
      )}
    </>
  );
}

export default Image;
