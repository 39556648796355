import Feature, { FEATURES_VARIANT } from 'UI/Feature';

import Desktop from 'assets/Techrehab/Desktop.png';
import SingleDesign from 'assets/Techrehab/single_design.png';
import Mobile from 'assets/Techrehab/mobile.png';
import Illustration from 'assets/Techrehab/3D_Illustration.png';
import SideWallpaper from 'assets/Techrehab/side_wallpaper.svg';

export const setFeatures = () => {
  const features = [
    {
      id: 0,
      className:
        'bg-[#8DB1FF] h-[500px] p-3 overflow-hidden rounded-3xl md:h-[600px]',
      col: {
        md: 4,
        xl: 3,
      },
      component: (
        <Feature
          className='gap-8 lg:gap-10'
          variant={FEATURES_VARIANT.IMAGE}
          img={{
            src: SingleDesign,
            alt: 'search-job',
          }}
        />
      ),
    },
    {
      id: 1,
      className:
        'bg-[#DDE3E6] w-full h-[500px] overflow-hidden rounded-3xl xl:row-span-2',
      col: {
        md: 2,
        xl: 2,
      },
      component: (
        <Feature
          className='gap-8 lg:gap-12'
          content={{
            label: 'Designing for Desktop',
          }}
          img={{
            src: Desktop,
            alt: 'design-desktop',
          }}
        />
      ),
    },
    {
      id: 2,
      className:
        'bg-[#6091FF] h-[500px] overflow-hidden rounded-3xl rounded-3xl xl:row-span-2',
      col: {
        md: 2,
        xl: 1
      },
      component: (
        <Feature
          className='gap-8 text-white'
          content={{
            label: 'Designing for Mobile',
          }}
          img={{
            src: Mobile,
            alt: 'design-mobile',
          }}
        />
      ),
    },
    {
      id: 3,
      className:
        'border border-gray-600 pb-1.5 overflow-hidden rounded-3xl rounded-3xl xl:row-span-1',
      col: {
        md: 4,
        xl: 1
      },
      component: (
        <Feature
          className='gap-8 justify-center items-center text-center'
          content={{
            label: '3D Illustration',
          }}
          img={{
            src: Illustration,
            alt: 'illustration-3D',
          }}
          reverse
        />
      ),
    },
    {
      id: 4,
      className:
        'bg-[#9CBBFF] h-[500px] overflow-hidden rounded-3xl',
      col: {
        md: 4,
        xl: 2
      },
      component: (
        <Feature
          className='gap-8 lg:gap-10'
          variant={FEATURES_VARIANT.IMAGE}
          img={{
            src: SideWallpaper,
            alt: 'search-job',
          }}
        />
      ),
    },
  ];

  return features;
};
