import { ButtonIcon, ButtonPrimary, ButtonSecondary, Link } from './variants';

export const BUTTON_VARIANTS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  ICON: 'icon',
  LINK: 'link',
};

export const renderComponent = (
  variant,
  link,
  icon,
  text,
  defaultWidth,
  hovered,
  className,
  props
) => {
  let variants = '';

  switch (variant) {
    case BUTTON_VARIANTS.PRIMARY:
      variants = (
        <ButtonPrimary
          link={link}
          icon={icon}
          text={text}
          className={className}
          {...props}
        />
      );
      break;
    case BUTTON_VARIANTS.SECONDARY:
      variants = (
        <ButtonSecondary link={link} icon={icon} text={text} {...props} />
      );
      break;
    case BUTTON_VARIANTS.ICON:
      variants = (
        <ButtonIcon
          text={text}
          hovered={hovered}
          defaultWidth={defaultWidth}
          {...props}
        />
      );
      break;
    case BUTTON_VARIANTS.LINK:
      variants = (
        <Link
          link={link}
          icon={icon}
          text={text}
          className={className}
          {...props}
        />
      );
      break;
    default:
      break;
  }

  return variants;
};
