export const facts = [
  {
    label: 'Role',
    role: 'UX/UI Designer',
  },
  {
    label: 'Duration',
    role: '12 weeks',
  },
  {
    label: 'Project',
    role: 'IoT Smart Shower University Project',
  },
];

export const links = [
  {
    id: 0,
    url: 'https://medium.com/@azini10001/smart-shower-bubbly-dashboard-41ca79360e41',
    title: 'Explore Full Case Study',
  },
  {
    id: 1,
    url: 'https://www.figma.com/file/WBOvXAfYVJswp8mbgtuYYC/Bubbly-Design-System?type=design&node-id=2317%3A1219&mode=design&t=GCMHJgECMmkw5T1n-1',
    title: 'Design System',
  },
  {
    id: 2,
    url: 'https://bubbly-dd8f0.firebaseapp.com/',
    title: 'View Website',
  },
];

export const improvements = [
  {
    id: 0,
    value:
      'Enhance the design system by incorporating additional components for both the dashboard. Re-structuring the icons by adding miscellaneous icons, company logos, and client logos.',
  },
  {
    id: 1,
    value:
      'Introduce design tokens, through colours, fonts, spacing, animations, and other assets. These can help manage the variables and styles used across the system.',
  },
  {
    id: 2,
    value:
      'Implement design amendments based on usability test feedback, with a focus on improving metrics such as feedback, navigation, and layout.',
  },
  {
    id: 3,
    value:
      'Conduct further user research, specifically contextual enquiries, to gain a comprehensive understanding of user environments and needs.',
  },
  {
    id: 4,
    value:
      'Create some micro interactions to boost user engagement and interaction within the web application.',
  },
];

export const keylessons = [
  {
    id: 0,
    value:
      'In our initial research, we drew from an older concept and conducted user studies primarily focusing on individual shower interactions due to time constraints we were not able to conduct further user research. Recognising the indirect nature of our data, we adapted by shifting our focus to SMART Shower dashboard and meter device.',
  },
  {
    id: 1,
    value:
      'Original research questions were too biased towards the original proof of concept. Recognising this bias is crucial, emphasising the importance of unbiased inquiries for a better understanding of shower usage context.',
  },
  {
    id: 2,
    value:
      'More questions should have been asked during the interview process to enhance result comprehension.',
  },
  {
    id: 3,
    value:
      'Acknowledging the indirect data nature, we recognize the need for contextual inquiries. A deeper understanding of users work environment, pain points, and needs would enhance the development of Bubblys SMART Shower meter.',
  },
];
