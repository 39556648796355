import { motion } from 'framer-motion';
import useComponent from 'react-hooks/useComponent';
import useAnimate, { fade } from 'react-hooks/useAnimate';

function FeatureCentre({ img, content, reverse, className }) {
  const { toggleClasses } = useComponent();
  const { controls, containerRef, variant: animateVariant } = useAnimate(fade);

  return (
    <div className='features flex flex-col justify-center items-center px-7 py-9'>
      <motion.div
        className={`features__container flex justify-center items-center ${
          reverse ? 'flex-col-reverse' : 'flex-col'
        } ${className ? className : ''}`}
        initial='hidden'
        animate={controls}
        variants={animateVariant}
        ref={containerRef}
      >
        <div className='features__content flex flex-col gap-3 w-full items-center justify-center text-center'>
          <label htmlFor='' className='text-lg font-semibold w-full'>
            {content.label}
          </label>
          <p
            className={`text-base font-medium md:w-2/3 lg:w-fit ${toggleClasses(
              '',
              'text-gray-700'
            )}`}
          >
            {content.paragraph}
          </p>
        </div>

        <div className='features__illustration w-full flex flex-col justify-center items-center'>
          <div
            className={`features__chart ${img.className ? img.className : ''}`}
          >
            <picture>
              <img src={img.src} alt={img.alt} />
            </picture>
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export default FeatureCentre;
