import { motion } from 'framer-motion';
import useAnimate, { fade } from 'react-hooks/useAnimate';

function FeatureImage({ img }) {
  const { containerRef, controls, variant: animateVariants } = useAnimate(fade);

  return (
    <motion.picture
      initial='hidden'
      animate={controls}
      variants={animateVariants}
      ref={containerRef}
      className='w-full h-full'
    >
      <img className='object-cover w-full h-full' src={img.src} alt={img.alt} />
    </motion.picture>
  );
}

export default FeatureImage;
