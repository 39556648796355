import Icon from "Icons";

export function DropdownItem({ text, link, icon }) {
    return (
        <a href={link.path} target={link.target} className="dropdown__item flex flex-row justify-center items-center p-1 hover:opacity-50">
            <div className="item__container flex flex-row gap-1.5 justify-center items-center">
                {icon.isTrailing && 
                    <div className="dropdown-list-item__icon h-4 w-4 grid justify-center items-center">
                        <Icon 
                            feather={icon.feather}
                            stroke={icon.stroke.leading}
                            icon={`${icon.icon.leading ? icon.icon.leading : 'plus'}`}
                        />
                    </div>
                }

                <div className="item__content w-full" >
                    <label className="content__label font-medium text-base">{text}</label>
                </div>

                {icon.isLeading && 
                    <div className="list-item__icon h-4 w-4 grid justify-center items-center">
                        <Icon 
                            feather={icon.feather}
                            stroke={icon.stroke.leading}
                            icon={`${icon.icon.leading ? icon.icon.leading : 'plus'}`}
                        />
                    </div>
                }
            </div>
        </a>
    )
}