import Icon from 'Icons';
import useComponent from 'react-hooks/useComponent';

export function Badge({ label }) {
  const { toggleClasses: toggle } = useComponent();

  <div
    className={`badge__label font-semibold  ${toggle(
      'text-navy-400',
      'text-white'
    )} `}
  >
    <label htmlFor=''>{label}</label>
  </div>;
}

export function BadgeDot({ label, icon }) {
  const { toggleClasses: toggle } = useComponent();

  return (
    <>
      {icon ? (
        <div className='badge__icon'>
          <Icon
            icon={icon.icon}
            feather={icon.feather}
            stroke={icon.stroke}
            fill={icon.fill}
            size={8}
          />
        </div>
      ) : null}
      <div
        className={`badge__label font-semibold ${toggle(
          'text-navy-400',
          'text-white'
        )}`}
      >
        <label htmlFor=''>{label}</label>
      </div>
    </>
  );
}

export function BadgeMessage({ label, icon, message }) {
  const { toggleClasses } = useComponent();

  return (
    <>
      <div
        className={`badge__label font-semibold ${toggleClasses(
          'text-navy-400',
          'text-white'
        )}`}
      >
        <label htmlFor=''>{label}</label>
      </div>
      {icon ? (
        <div className='badge__icon'>
          <Icon
            icon={icon.icon}
            feather={icon.feather}
            fill={icon.fill}
            stroke={icon.stroke}
            size={8}
          />
        </div>
      ) : null}
      <div
        className={`badge__message font-medium ${toggleClasses(
          'text-navy-400',
          'text-white'
        )}`}
      >
        <label htmlFor=''>{message}</label>
      </div>
    </>
  );
}
