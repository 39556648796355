export const handleProjectEnter = (e) => {
  const projectVid = e.target;
  projectVid.muted = true;

  // Ensure the video is not already playing before attempting to play
  if (projectVid.paused) {
    projectVid.play().catch((error) => {
      console.error('Error playing video:', error);
    });
  }
};
export const handleProjectLeave = (e) => {
  const projectVid = e.target;
  projectVid.muted = false;
  projectVid.currentTime = 0;

  // Ensure the video is playing before attempting to pause
  if (!projectVid.paused) {
    projectVid.pause();
  }
};

export const check = (item, selectedCategory) =>
  selectedCategory !== 'All' && item.category !== selectedCategory
    ? 'opacity-20'
    : '';

export const checkEnter = (
  item,
  selectedCategory,
  hoverState,
  setHoverState
) => {
  if (selectedCategory !== 'All' && item.category !== selectedCategory) {
    setHoverState({ ...hoverState, [item.id]: false });
  } else {
    setHoverState({ ...hoverState, [item.id]: true });
  }
};
