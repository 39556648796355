import { createPortal } from 'react-dom';

export default function usePortal(id) {
  const root = document.getElementById(id);

  const portal = (element, root) => createPortal(element, root);

  const createElement = (eId) => {
    const element = document.createElement('div');
    element.setAttribute('id', eId);
    document.body.appendChild(element);
    return element;
  };

  function Portal({ children, root }) {
    return portal(children, root);
  }

  return {
    root,
    Portal,
    createElement,
  };
}
