import { useEffect } from 'react';
import { useNavigate } from 'react-router';

import useAuth from 'react-hooks/useAuth';

const withProtected = (Child) => {
  function Component({ ...props }) {
    const navigate = useNavigate();
    const { user, logOut } = useAuth();

    useEffect(() => {
      if (!user) {
        navigate('/login');
      }
    });

    useEffect(() => {
      if (!user) return; // Don't set a timeout if there's no user

      // Set a timeout to log the user out after 1 hour
      const timeoutId = setTimeout(() => {
        logOut().then(() => {
          navigate('/login');
        });
      }, 3600000); // 1 hour in milliseconds

      // Cleanup function to clear the timeout on component unmount or when user changes
      return () => clearTimeout(timeoutId);
    }, [user, navigate]);

    return <Child {...props} />;
  }
  return Component;
};

export default withProtected;
