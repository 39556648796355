import useComponent from 'react-hooks/useComponent';

import Headings, { HEADING_VARIANTS } from 'Shared-UI/Headings';

function Heading({ showSubtitle = false, headings, className }) {
  const { toggleClasses } = useComponent();

  return (
    <div
      className={`heading-section flex flex-col gap-2 w-full ${
        className ? className : ''
      }`}
    >
      {showSubtitle && (
        <Headings
          heading={HEADING_VARIANTS.H5}
          title={headings.subtitle}
          className={`${toggleClasses('text-brand-500', 'text-brand-400')}`}
        />
      )}
      <Headings
        heading={headings.heading}
        title={headings.title}
        className={headings.className}
      />
    </div>
  );
}

export default Heading;
