import Main from './base-components/Main';
import { switchAsideComponent } from './utils';

function Aside({ content, img, reverse }) {
  const rowClass = reverse
    ? 'flex-col lg:flex-row-reverse'
    : 'flex-col lg:flex-row';

  return (
    <div
      className={`aside-section flex gap-6 ${rowClass} lg:justify-center xl:gap-12`}
    >
      <Main title={content.title} paragraph={content.paragraph} />
      {switchAsideComponent(img, content)}
    </div>
  );
}

export default Aside;
