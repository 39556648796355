const firebaseCVLink =
  'https://firebasestorage.googleapis.com/v0/b/portfolio-database-2438e.appspot.com/o/Alex%20Zietara%20Nicholls.pdf?alt=media&token=bdd568e5-ce1b-4462-9872-27704dc58ae6';

export const linksArray = (dropdown) => {
  const links = [
    {
      id: 0,
      text: 'Projects',
      link: {
        path: '/',
      },
      icon: {
        isTrailing: false,
        isLeading: dropdown ? true : false,
        feather: false,
        stroke: {
          leading: 'black',
        },
        icon: {
          leading: 'arrow-up',
        },
      },
    },
    {
      id: 1,
      text: 'Resume',
      link: {
        path: firebaseCVLink,
        target: '__blank',
      },
      icon: {
        isTrailing: false,
        isLeading: dropdown ? true : false,
        feather: false,
        stroke: {
          leading: 'black',
        },
        icon: {
          leading: 'arrow-up',
        },
      },
    },
    {
      id: 2,
      text: 'Contact',
      link: {
        path: 'mailto:azini@live.co.uk',
      },
      icon: {
        isTrailing: false,
        isLeading: dropdown ? true : false,
        feather: false,
        stroke: {
          leading: 'black',
        },
        icon: {
          leading: 'arrow-up',
        },
      },
    },
  ];
  return links;
};
