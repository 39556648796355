import Feature, { FEATURES_VARIANT } from 'UI/Feature';

import BubblyThumbnail from 'assets/bubbly_thumbnail.svg';
import Chart from 'assets/Bubbly/Chart.png';
import ButtonAnalysis from 'assets/Bubbly/button_analysis.png';
import SideBar from 'assets/Bubbly/bubbly_4pt_grid.png';
import DarkModeSwitch from 'assets/Bubbly/ToggleSwitch.png';

import BubblyThumbnailDark from 'assets/bubbly_thumbnail--night.svg';
import ChartDark from 'assets/Bubbly/NightMode/Chart--dark.png';

export const setFeatures = (toggle, toggleClasses) => {
  const features = [
    {
      id: 0,
      className: `border h-[700px] rounded-3xl overflow-hidden ${toggleClasses(
        '',
        'bg-gray-400 border-gray-600'
      )}`,
      col: {
        md: 4,
        xl: 2,
      },
      component: (
        <Feature
          className='gap-8 lg:gap-12'
          variant={FEATURES_VARIANT.IMAGE}
          img={{
            src: toggle(BubblyThumbnail, BubblyThumbnailDark),
            alt: 'search-job',
          }}
        />
      ),
    },
    {
      id: 1,
      className: `border overflow-hidden rounded-3xl ${toggleClasses(
        'border-gray-600',
        'border-navy-600'
      )}`,
      col: {
        md: 2,
      },
      component: (
        <Feature
          variant={FEATURES_VARIANT.RIGHT}
          content={{
            label: 'Data Visualisation',
            paragraph:
              'Charts, Tables and Bento grids are used to clearly help represent real-time data in a simple visual way.',
          }}
          img={{
            src: toggle(Chart, ChartDark),
            alt: 'message-sharing',
            className:
              'h-24 w-full [&>*]:relative [&>*]:w-56 [&>*]:-bottom-12 [&>*]:-right-12 [&>*]:xl:-bottom-8 [&>*]:xl:w-64 [&>*]:xl:-right-20',
          }}
        />
      ),
    },
    {
      id: 2,
      className: `border relative overflow-hidden rounded-3xl xl:flex-row ${toggleClasses(
        'border-gray-600',
        'border-navy-600'
      )}`,
      col: {
        md: 2,
      },
      component: (
        <Feature
          variant={FEATURES_VARIANT.RIGHT}
          content={{
            label: 'Designing Buttons',
            paragraph:
              'Corner radius and dark blue font with a shadow enhance accessiblity and user comfort while maintaining a modern, professional appearance.',
          }}
          img={{
            src: ButtonAnalysis,
            alt: 'simplify-job-search',
          }}
        />
      ),
    },
    {
      id: 3,
      className:
        'border border-gray-600 overflow-hidden rounded-3xl xl:row-span-2 xl:row-start-1 xl:col-start-3',
      col: {
        md: 4,
        xl: 1,
      },
      component: (
        <Feature
          variant={FEATURES_VARIANT.CENTRE}
          content={{
            label: '4pt Grid System for Spacing',
            paragraph:
              '4pt Grid system on Left Sidebar to align spacing, margin and padding.',
          }}
          img={{
            src: SideBar,
            alt: 'message-sharing',
          }}
        />
      ),
    },
    {
      id: 4,
      className: 'border border-gray-600 overflow-hidden rounded-3xl',
      col: {
        md: 4,
        xl: 1,
      },
      component: (
        <Feature
          variant={FEATURES_VARIANT.CENTRE}
          className='gap-4 lg:gap-6 [&>img]:w-fit'
          content={{
            label: 'Dark Mode',
            paragraph:
              'Beneficial for continous operations, it reduces eye strain and enhances visibility in low-light conditions. Ideal for night-time usage.',
          }}
          img={{
            src: DarkModeSwitch,
            alt: 'data-visualisation',
          }}
        />
      ),
    },
  ];

  return features;
};
