import { useMemo, useEffect } from 'react';
import useAnimate, { reveal } from 'react-hooks/useAnimate';
import useComponent from 'react-hooks/useComponent';
import { setVariants } from './utils';
export { HEADING_VARIANTS } from './utils';

function Headings({ heading, title, className }) {
  const { containerRef, controls, variant } = useAnimate(reveal);
  const { component, renderVariants, toggleClasses } = useComponent(heading);

  const headings = useMemo(
    () => setVariants(controls, variant, title, className, toggleClasses),
    [controls, variant, title, className, toggleClasses]
  );

  useEffect(() => {
    renderVariants(headings);
  }, [headings, renderVariants]);

  return (
    <div
      ref={containerRef}
      className={`${className ? className : ''} reveal-heading overflow-hidden`}
    >
      {component}
    </div>
  );
}

export default Headings;
