import { useRef, useEffect } from 'react';
import { useInView, useAnimation } from 'framer-motion';

import Layout, { LAYOUT_VARIANTS } from 'Layout';
import Container from 'Grid/Container';

import { HEADING_VARIANTS } from 'Shared-UI/Headings/utils';

import Section, {
  MEDIA_VARIANTS,
  INFORMATION_VARIANTS,
  SECTION_VARIANTS,
} from 'UI/Section';
import Bento from 'UI/Bento';

import Wallpaper from 'assets/Techrehab/hero_wallpaper.png';
import DesignGuide from 'assets/Techrehab/wallpaper.png';

import { Meta, techrehabtags } from '../..';
import { setFeatures } from './features';
import { brandings } from './utils';
import useAnimate, { fade } from 'react-hooks/useAnimate';

const facts = [
  {
    label: 'Role',
    role: 'UI Designer',
  },
  {
    label: 'Duration',
    role: '8 Weeks',
  },
  {
    label: 'Project',
    role: 'Rebranding Tech Project',
  },
];

function Techrehab() {
  const features = setFeatures();

  return (
    <Layout
      variant={LAYOUT_VARIANTS.PROJECT}
      meta={{
        title: 'Steady Paycheck: Revolutionsing Job Recruitment App',
        tags: techrehabtags,
      }}
      project={{
        name: 'techrehab',
      }}
      hero={{
        title: 'Revitalising a Decade-Strong Legacy with Modern Design',
        subtitle: 'Techrehab',
        project: {
          img: { src: Wallpaper, alt: 'techrehab-wallpaper' },
        },
        className: 'bg-[#8DB1FF]',
      }}
      scenario={{
        content: {
          paragraph:
            'TechRehab is a well established computer repair company with over ten years  experience in repairing well-known branded technical services. The client faces a challenge with outdated branding and an obsolete online presence. They seek a comprehensive rebranding and solution to modernise their identity, enhance their online visibility, and improve user engagement.',
          facts: facts,
        },
      }}
    >
      <Meta
        title='Techrehab: Revitalising a Decade-Strong Legacy with Modern Design'
        tags={techrehabtags}
      />
      <Container full>
        <Section
          variant={SECTION_VARIANTS.MEDIA}
          media={{
            variant: MEDIA_VARIANTS.IMAGE,
            media: {
              img: {
                src: DesignGuide,
                alt: 'Design Guide - Techrehab',
              },
            },
            className: 'bg-[#8DB1FF]',
          }}
        />
      </Container>
      <Container>
        <div className='techrehab__branding flex flex-col gap-7 lg:gap-9'>
          <Section
            variant={SECTION_VARIANTS.HEADING}
            heading={{
              headings: {
                heading: HEADING_VARIANTS.H2,
                title: 'New Modern Identity',
                subtitle: 'Branding',
              },
              showSubtitle: true,
              className:
                'text-left md:[&>*]:text-center [&>*]:xl:w-96 [&>*]:xl:m-auto',
            }}
          />
          <Section
            variant={SECTION_VARIANTS.INFORMATION}
            information={{
              variant: INFORMATION_VARIANTS.PARAGRAPH,
              contents: {
                title: 'Rebranding',
                paragraph:
                  'TechRehabs revitalized brand boasts a new logo, modern styles, a fresh color palette, and 3D illustrations. The redesigned logo reflects the companys values, with refined color and typography choices that align with modern design trends. This updated brand identity positions TechRehab as an innovative leader, appealing to its target audience with a contemporary and compelling presence.',
              },
            }}
          />
        </div>
      </Container>
      <Container>
        <div className='techrehab__branding flex flex-col gap-7 lg:gap-9'>
          {brandings.map((branding) => (
            <Section
              variant={SECTION_VARIANTS.ASIDE}
              aside={{
                content: branding.content,
                img: branding.img,
                reverse: branding.reverse,
              }}
            />
          ))}
        </div>
      </Container>
      <Container>
        <div className='techrehab__rebranding flex flex-col gap-7 lg:gap-9'>
          <Section
            variant={SECTION_VARIANTS.HEADING}
            heading={{
              headings: {
                heading: HEADING_VARIANTS.H2,
                title: 'Engaging Online Experience',
                subtitle: 'Design',
              },
              showSubtitle: true,
              className:
                'text-left md:[&>*]:text-center [&>*]:xl:w-96 [&>*]:xl:m-auto',
            }}
          />
          <Section
            variant={SECTION_VARIANTS.INFORMATION}
            information={{
              variant: INFORMATION_VARIANTS.PARAGRAPH,
              contents: {
                title: 'Redesign',
                paragraph:
                  'In our website redesign, we prioritize user friendly experiences. The desktop landing page impresses with an engaging hero section. 3D elements enhanced the visual appeal and spotlight key features. Responsive design with breakpoints ensures smooth access on various devices, while mobile users enjoy a user-friendly experience. ',
              },
            }}
          />
          <div className='grid gap-4 md:grid-cols-4 xl:grid-cols-3'>
            {features.map((feature) => (
              <Bento
                key={feature.id}
                col={feature.col}
                className={feature.className}
              >
                {feature.component}
              </Bento>
            ))}
          </div>
        </div>
      </Container>
    </Layout>
  );
}

export default Techrehab;
