import { useMemo } from 'react';
import { useState } from 'react';
import { ThemeContext } from './context';
export { ThemeContext } from './context';

function ThemeProvider({ children }) {
  const [darkMode, setDarkMode] = useState(false);

  const toggleMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  const theme = useMemo(() => ({ darkMode, toggleMode }), [darkMode]);

  return (
    <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
  );
}

export default ThemeProvider;
