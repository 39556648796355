import useComponent from 'react-hooks/useComponent';

export function ServerMessage({ error }) {
  const { toggleClasses } = useComponent();

  return (
    <div
      className={`flex flex-row align-start border p-1 rounded-md w-full ${toggleClasses(
        'bg-error-50 border-error-100 text-error-300',
        'bg-error-100 border-error-200 text-error-300'
      )}`}
    >
      <p>Error: {error}</p>
    </div>
  );
}
