import { motion } from 'framer-motion';
import useAnimate, { fade } from 'react-hooks/useAnimate';
import useComponent from 'react-hooks/useComponent';

function FeatureRight({ img, content, className }) {
  const { toggleClasses } = useComponent();
  const { controls, containerRef, animate: animateVariant } = useAnimate(fade);

  return (
    <div className='flex flex-col px-7 py-9 lg:flex-row'>
      <motion.div
        initial='hidden'
        animate={controls}
        variants={animateVariant}
        ref={containerRef}
        className={`bento-container--horizontal flex flex-col gap-5 xl:flex-row ${
          className ? className : ''
        }`}
      >
        <div className='bento-content flex flex-col gap-3 w-full'>
          <label htmlFor='' className='text-lg font-semibold w-full'>
            {content.label}
          </label>
          <p
            className={`text-base font-medium md:w-2/3 lg:w-fit ${toggleClasses(
              '',
              'text-gray-700'
            )}`}
          >
            {content.paragraph}
          </p>
        </div>

        <div
          className={`bento-illustration w-full flex flex-col justify-center items-center ${
            img.className ? img.className : ''
          }`}
        >
          <div className='bento-illustration__chart'>
            <picture>
              <img src={img.src} alt='Data Visualisation' />
            </picture>
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export default FeatureRight;
