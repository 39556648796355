import { useRef, useEffect } from 'react';
import { useAnimation, useInView } from 'framer-motion';

import useComponent from 'react-hooks/useComponent';

import Container from 'Grid/Container';
import withProtected from 'hoc/withProtected';
import Layout, { LAYOUT_VARIANTS } from 'Layout';

import Section, {
  SECTION_VARIANTS,
  INFORMATION_VARIANTS,
  MEDIA_VARIANTS,
} from 'UI/Section';

import { HEADING_VARIANTS } from 'Shared-UI/Headings/utils';

import Bento from 'UI/Bento';

import { setFeatures } from './features';
import { facts, improvements, keylessons, links } from './utils';
import { bubblytags } from '../..';

import Persona from 'assets/Bubbly/persona.png';
import UserFlow from 'assets/Bubbly/userflow.webm';
import UserFlowMov from 'assets/Bubbly/userflow.mov';
import NapkinSketches from 'assets/Bubbly/sketches.png';
import Wallpaper from 'assets/Bubbly/Wallpaper-Bubbly.png';
import BubblyHero from 'assets/Bubbly/Bubbly_Wallpaper2.png';
import Prototype from 'assets/Bubbly/prototype.png';
import UserJourney from 'assets/Bubbly/userjourney.png';

import PersonaDark from 'assets/Bubbly/NightMode/Persona--dark.png';
import UserFlowDark from 'assets/Bubbly/NightMode/userflow--dark.webm';
import UserFlowDarkMov from 'assets/Bubbly/NightMode/userflow--dark.mov';
import PrototypeDark from 'assets/Bubbly/NightMode/prototype--dark.png';
import WallpaperDark from 'assets/Bubbly/NightMode/Wallpaper-Bubbly--dark.png';

function Bubbly() {
  const motionRef = useRef();
  const isInView = useInView(motionRef, { once: true });
  const controls = useAnimation();
  const { toggle, toggleClasses } = useComponent();

  const features = setFeatures(toggle, toggleClasses);

  useEffect(() => {
    if (isInView) {
      controls.start('visible');
    }
  }, [isInView, controls]);

  return (
    <Layout
      variant={LAYOUT_VARIANTS.PROJECT}
      meta={{ title: 'Bubbly: Smart Dashboard', tags: bubblytags }}
      project={{
        name: 'bubbly',
      }}
      hero={{
        title:
          'Smart Shower Dashboard to conserve Electricity, Water and Slash Bills',
        subtitle: 'Bubbly',
        project: {
          img: { src: BubblyHero, alt: 'bubbly-wallpaper' },
        },
        className: 'bg-[#E9EAF2]',
      }}
      scenario={{
        content: {
          paragraph:
            'Bubbly, an energy efficiency start-up, empowers clients, including public facilities and B2B companies, to conserve resources and save costs by retrofitting smart meters to existing bathroom showers. Through innovative IoT technology, we connect smart meter devices to showers, providing real-time data on costs, water usage, maintenance, and electricity consumption, fostering sustainable practices and significant savings for our clients.',
          facts: facts,
          links: links,
        },
      }}
    >
      <Container>
        <Section
          variant={SECTION_VARIANTS.ASIDE}
          aside={{
            content: {
              title: 'Problems',
              paragraph:
                'Showers consume 25% of water in homes (Rebecca Smithers, 2013). Hotels use 30-40% of water, equating to 120 liters per room daily (Johannes Tyler, 2015). Electric showers use 7kW to 10.5kW of electricity, costing approximately 12p per kW to heat a shower (Choose, 2018). Decreasing energy and water consumption not only saves costs for businesses and water companies but also reduces carbon emissions (South Staffs Water, 2018).',
              secondary: {
                title: 'Proposed Concepts',
                paragraph:
                  'Upgrade existing showers with a user-friendly digital system, offering personalised experiences for guests. Synced with a real-time dashboard, allows staff to monitor costs, water, electricity usage, and repairs. Retrofit smart meters onto showers, syncing with a real-time dashboard. Enabling staff to monitor costs, water, electricity usage, and repairs efficiently.',
              },
            },
          }}
        />
      </Container>

      <Container full>
        <Section
          variant={SECTION_VARIANTS.MEDIA}
          media={{
            variant: MEDIA_VARIANTS.IMAGE,
            media: {
              img: {
                src: toggle(Wallpaper, WallpaperDark),
                alt: 'View Showers - User Flow',
              },
            },
            className: `overflow-hidden md:h-[43-rem]  ${toggleClasses(
              'bg-gray-400',
              'bg-navy-500'
            )}`,
          }}
        />
      </Container>

      <Container>
        <div className='discover-section flex flex-col gap-7 lg:gap-9'>
          <Section
            variant={SECTION_VARIANTS.HEADING}
            heading={{
              headings: {
                heading: HEADING_VARIANTS.H2,
                title: 'Uncovering Insights into Users & Market Research',
                subtitle: 'Discover',
              },
              showSubtitle: true,
              className:
                'text-left md:[&>*]:text-center [&>*]:xl:w-96 [&>*]:xl:m-auto',
            }}
          />
          <Section
            variant={SECTION_VARIANTS.INFORMATION}
            information={{
              variant: INFORMATION_VARIANTS.PARAGRAPH,
              contents: {
                title: 'Research & Findings',
                paragraph:
                  'Competitors share commonalities in focusing on water usage monitoring, costing monitoring, system monitoring, and flexible payment options. Public showers are constantly breaking down or vandalised which in return require high maintenance. Showers in public facilities and businesses result in high water and energy consumption, leading to increased operational costs due to elevated water and energy bills. The lack of smart showers for high-end accommodations, gyms, swimming pools, and luxury residences, indicating a growing market demand for technologically advanced shower systems.',
              },
            }}
          />
        </div>
      </Container>

      <Container>
        <div className='define flex flex-col gap-7 lg:gap-9'>
          <Section
            variant={SECTION_VARIANTS.HEADING}
            heading={{
              headings: {
                heading: HEADING_VARIANTS.H2,
                title: 'Strategic Exploration into User Needs and Preferences',
                subtitle: 'Define',
              },
              showSubtitle: true,
              className:
                'text-left md:[&>*]:text-center [&>*]:xl:w-96 [&>*]:xl:m-auto',
            }}
          />
          <Section
            variant={SECTION_VARIANTS.INFORMATION}
            information={{
              variant: INFORMATION_VARIANTS.PARAGRAPH,
              contents: {
                title: 'Personas',
                paragraph:
                  'Utilising data from the user research, we created targeted personas, guiding the development of our SMART Shower project. This ensures our innovation aligns seamlessly with users diverse needs and preferences.',
              },
            }}
          />
          <Section
            variant={SECTION_VARIANTS.MEDIA}
            media={{
              variant: MEDIA_VARIANTS.IMAGE,
              media: {
                img: {
                  src: toggle(Persona, PersonaDark),
                  alt: 'Andrew Barn Smith - User Persona',
                },
              },
            }}
          />
        </div>
      </Container>

      <Container>
        <div className='define flex flex-col gap-7 lg:gap-9'>
          <Section
            variant={SECTION_VARIANTS.INFORMATION}
            information={{
              variant: INFORMATION_VARIANTS.PARAGRAPH,
              contents: {
                title: 'User Journey',
                paragraph:
                  'Data from the user research, provided the ability to produce a user journey based off over the users goals, frustrations from the personas. Allowing to provide a process on how users will complete their goals.',
              },
            }}
          />
          <Section
            variant={SECTION_VARIANTS.MEDIA}
            media={{
              variant: MEDIA_VARIANTS.IMAGE,
              media: {
                img: {
                  src: UserJourney,
                  alt: 'user_journey',
                },
              },
              className: toggleClasses('bg-gray-400', 'bg-navy-500'),
            }}
          />
        </div>
      </Container>

      <Container>
        <div className='define flex flex-col gap-7 lg:gap-9'>
          <Section
            variant={SECTION_VARIANTS.INFORMATION}
            information={{
              variant: INFORMATION_VARIANTS.PARAGRAPH,
              contents: {
                title: 'User Flows',
                paragraph:
                  'User flows mapped our SMART Shower journey, streamlining interactions and enhancing user experiences. These intuitive pathways ensure seamless navigation, from initial interaction to optimal user engagement.',
              },
            }}
          />
          <Section
            variant={SECTION_VARIANTS.MEDIA}
            media={{
              variant: MEDIA_VARIANTS.VIDEO,
              media: {
                video: {
                  type: {
                    type: 'video/mp4',
                    type2: 'video/mov',
                  },
                  src: {
                    source: toggle(UserFlow, UserFlowDark),
                    source2: toggle(UserFlowMov, UserFlowDarkMov),
                  },
                  alt: 'Bubbly - Userflow',
                },
              },
              className: `py-10 ${toggleClasses(
                'bg-[#F6F7F9]',
                'bg-[#20232A]'
              )}`,
              animated: true,
              rounded: true,
            }}
          />
        </div>
      </Container>

      <Container>
        <div className='design flex flex-col gap-7 lg:gap-9'>
          <Section
            variant={SECTION_VARIANTS.INFORMATION}
            information={{
              variant: INFORMATION_VARIANTS.PARAGRAPH,
              contents: {
                title: 'Napkin Sketches',
                paragraph:
                  'Napkin sketches or rough sketches were ideas, concepts and solutions. For both the dashboard and the shower meter concept.',
              },
            }}
          />

          <Section
            variant={SECTION_VARIANTS.MEDIA}
            media={{
              variant: MEDIA_VARIANTS.IMAGE,
              media: {
                img: {
                  src: NapkinSketches,
                  alt: 'Dashboard - Napkin Sketches',
                  caption: 'Rough sketches derived from the showers page',
                },
              },
            }}
          />
        </div>
      </Container>

      <Container>
        <div className='design flex flex-col gap-7 lg:gap-9'>
          <Section
            variant={SECTION_VARIANTS.INFORMATION}
            information={{
              variant: INFORMATION_VARIANTS.PARAGRAPH,
              contents: {
                title: 'Design System',
                paragraph:
                  'Implementing a design system in Figma for Bubblys smart shower dashboard ensures a consistent, efficient user experience.  It accelerates design and development, promotes collaboration, and simplifies usability testing and developer handoffs.',
              },
            }}
          />

          <div className='grid gap-4 md:grid-cols-4 xl:grid-cols-3'>
            {features.map((feature) => (
              <Bento key={feature.id} col={feature.col} className={feature.className}>
                {feature.component}
              </Bento>
            ))}
          </div>
        </div>
      </Container>

      <Container>
        <div className='develop flex flex-col gap-7 lg:gap-9'>
          <Section
            variant={SECTION_VARIANTS.HEADING}
            heading={{
              headings: {
                heading: HEADING_VARIANTS.H2,
                title: 'Usability Testing & Prototype Refinements',
                subtitle: 'Develop',
              },
              showSubtitle: true,
              className: '[&>*]:text-center [&>*]:xl:w-96 [&>*]:xl:m-auto',
            }}
          />
          <Section
            variant={SECTION_VARIANTS.INFORMATION}
            information={{
              variant: INFORMATION_VARIANTS.PARAGRAPH,
              contents: {
                title: 'Usability Testing',
                paragraph:
                  'This usability assessment targeted three key metrics: Navigation, Layout, and Feedback. The aim was to test the SMART dashboard in a controlled usability lab using Tobii Eye Tracking software. 5 participants, including founders, a hotel receptionist, a prison officer, and a hotel manager. We’re given different testing scenarios, to either view shower performances, check water consumption, and report faulty showers. Interviews unveiled user difficulties in reporting showers, despite the overall design being considered aesthetically pleasing. Participants found the system simple and clean, suggesting a need for improved navigation and feedback.',
              },
            }}
          />
          <Section
            variant={SECTION_VARIANTS.MEDIA}
            media={{
              variant: MEDIA_VARIANTS.IMAGE,
              media: {
                img: {
                  src: toggle(Prototype, PrototypeDark),
                  alt: 'View Showers - User Flow',
                },
              },
              className: `overflow-hidden md:h-[43-rem]  ${toggleClasses(
                'bg-gray-400',
                'bg-navy-500'
              )}`,
            }}
          />
        </div>
      </Container>

      <Container>
        <div className='summary flex flex-col gap-7 lg:gap-9'>
          <Section
            variant={SECTION_VARIANTS.INFORMATION}
            information={{
              variant: INFORMATION_VARIANTS.NUMBER,
              contents: {
                title: 'Improvements',
                contents: improvements,
              },
            }}
          />

          <Section
            variant={SECTION_VARIANTS.INFORMATION}
            information={{
              variant: INFORMATION_VARIANTS.NUMBER,
              contents: {
                title: 'Key Lessons',
                contents: keylessons,
              },
            }}
          />
        </div>
      </Container>
    </Layout>
  );
}

export default withProtected(Bubbly);
