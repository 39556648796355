import { motion } from 'framer-motion';
import useAnimate, { fade } from 'react-hooks/useAnimate';

function Picture({ img }) {
  return (
    <picture className='rounded-[inherit] w-full'>
      <img
        className='rounded-[inherit] w-full'
        src={img.src}
        alt={img.alt}
        srcSet={img.srcset}
      />
    </picture>
  );
}

function Image({ className, img }) {
  const { containerRef, controls, variant: fadeVariants } = useAnimate(fade);

  return (
    <motion.div
      ref={containerRef}
      className={`aside_image rounded-3xl flex flex-col xl:w-full ${className}`}
      initial='hidden'
      animate={controls}
      variants={fadeVariants}
    >
      <Picture img={img} />
    </motion.div>
  );
}

export default Image;
