import { motion } from 'framer-motion';

import useComponent from 'react-hooks/useComponent';
import useAnimate, { reveal } from 'react-hooks/useAnimate';

function LinkItem({ link, controls, toggleClasses }) {
  const { variant: animateVariants } = useAnimate(reveal);

  return (
    <li key={link.id} className='scenario__link-item'>
      <motion.a
        variants={animateVariants}
        animate={controls}
        initial='hidden'
        className={`link relative text-lg leading-9 font-medium before:content-[""] before:absolute before:block before:w-full before:h-[2px] before:bottom-[-2px] before:left-0 before:bg-brand-500 before:scale-x-0 before:[transition:transform_0.5s_ease] before:origin-top-right hover:before:scale-x-100 ${toggleClasses(
          'text-brand-500',
          'text-brand-400'
        )}`}
        href={link.url}
        target='_blank'
      >
        {link.title}
      </motion.a>
    </li>
  );
}

function Links({ links }) {
  const { toggleClasses } = useComponent();
  const { containerRef, controls } = useAnimate();

  return (
    <div className='scenario__links flex flex-col gap-6 md:flex-row'>
      <ul
        className='scenario__links-list overflow-hidden flex flex-wrap align-stretch gap-4 md:flex-row'
        ref={containerRef}
      >
        {links
          ? links.map((link, index) => (
              <LinkItem
                key={index}
                toggleClasses={toggleClasses}
                link={link}
                controls={controls}
              />
            ))
          : null}
      </ul>
    </div>
  );
}

export default Links;
