import { motion } from 'framer-motion';

import useComponent from 'react-hooks/useComponent';

function Tab({ onClick, id, label, activeTab }) {
  const { toggleClasses } = useComponent();

  const activeTabClass =
    activeTab === id
      ? 'font-semibold'
      : `${toggleClasses('hover:text-navy-400', 'hover:text-gray-200')}`;

  const allTabCheck = label === 'All' ? 'px-3 md:px-4' : 'px-2';

  return (
    <button
      key={id}
      onClick={onClick}
      className={`tab relative flex flex-col text-base font-medium items-center justify-center py-1.5 md:px-3 outline-2 outline-navy-500 transition focus-visible:outline ${allTabCheck} ${activeTabClass} ${toggleClasses(
        'hover:text-navy-600',
        'text-gray-300 hover:text-navy-100'
      )}`}
    >
      {activeTab === id && (
        <motion.div
          layoutId='active-pill'
          className={`tab--active absolute inset-0 ${toggleClasses(
            'bg-white',
            'bg-navy-900'
          )}`}
          style={{ borderRadius: 9999 }}
          transition={{ type: 'spring', duration: 0.6 }}
        />
      )}

      <span className='tab__label relative z-10'>{label}</span>
    </button>
  );
}

export default Tab;
