import { useState } from 'react';
import Icon from 'Icons';

import usePortal from 'react-hooks/usePortal';
import useComponent from 'react-hooks/useComponent';
import useAuth from 'react-hooks/useAuth';

import Avatar from 'Shared-UI/Avatar';
import Dropdown from 'Shared-UI/Dropdown';
import Button, { BUTTON_VARIANTS } from 'Shared-UI/Button';

import Nav from 'UI/Nav';

import { linksArray } from './utils';

function Header() {
  const { user, logOut } = useAuth();
  const [dropdown, setDropdown] = useState(false);

  const { Portal } = usePortal();
  const { toggleClasses } = useComponent();

  const links = linksArray(dropdown);

  const handleToggleMenu = (e) => {
    e.preventDefault();
    setDropdown(!dropdown);
  };

  async function handleSignOut(e) {
    e.preventDefault();
    try {
      await logOut();
      console.log('Success!');
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <div className='header-wrapper py-3 flex flex-row justify-center items-center w-full lg:py-7'>
        <div
          className={`header flex flex-row items-center justify-between gap-2 border p-1 w-full rounded-full md:gap-7 md:w-fit ${toggleClasses(
            'border-gray-600',
            'border-navy-600'
          )}`}
        >
          <div className='header__avatar bg-brand-500 rounded-full md:p-0.5'>
            <Avatar height={40} width={40} />
          </div>
          <div className='header__nav'>
            <Nav links={links} />
          </div>
          <div className='header__cta flex flex-row gap-2'>
            {user ? (
              <Button
                variant={BUTTON_VARIANTS.SECONDARY}
                icon={{ isTrailing: false, isLeading: false }}
                text='Log Out'
                onClick={(e) => handleSignOut(e)}
              />
            ) : (
              <Button
                variant={BUTTON_VARIANTS.SECONDARY}
                icon={{ isTrailing: false, isLeading: false }}
                text='Log In'
                link={{
                  to: '/login',
                }}
              />
            )}

            {/* Change this to icon button */}
            <div
              className={`hamburger flex rounded-full py-1.5 px-1.5 border md:hidden ${toggleClasses(
                'border-gray-600',
                'border-navy-600'
              )}`}
              onClick={handleToggleMenu}
            >
              <Icon feather icon='menu' fill={'black'} />
            </div>
          </div>
        </div>
      </div>
      <Portal
        children={
          <>
            {dropdown ? (
              <div className='dropdown-wrapper absolute top-12 right-4'>
                <Dropdown links={links} />
              </div>
            ) : null}
          </>
        }
        root={document.body}
      />
    </>
  );
}

export default Header;
