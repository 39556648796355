import FeatureCentre from './__base-components/FeatureCentre';
import FeatureRight from './__base-components/FeatureRight';
import FeatureLeft from './__base-components/FeatureLeft';
import FeatureImage from './__base-components/FeatureImage';

export const FEATURES_VARIANT = {
  CENTRE: 'CENTRE',
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
  IMAGE: 'IMAGE',
};

export const setVariants = (content, img, reverse, className) => {
  const variants = {
    [FEATURES_VARIANT.CENTRE]: () => (
      <FeatureCentre
        content={content}
        img={img}
        reverse={reverse}
        className={className}
      />
    ),
    [FEATURES_VARIANT.LEFT]: () => (
      <FeatureLeft content={content} img={img} reverse={reverse} />
    ),
    [FEATURES_VARIANT.RIGHT]: () => (
      <FeatureRight content={content} img={img} reverse={reverse} />
    ),
    [FEATURES_VARIANT.IMAGE]: () => (
      <FeatureImage content={content} img={img} reverse={reverse} />
    ),
  };

  variants['default'] = variants[FEATURES_VARIANT.CENTRE];

  return variants;
};
