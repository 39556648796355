import { Col } from 'Grid';

function Bento({ col, className, style, children }) {
  return (
    <Col className={className} col={col} style={style}>
      {children}
    </Col>
  );
}

export default Bento;
