import { useState } from 'react';

import useComponent from 'react-hooks/useComponent';

import Tab from './Tab';

let tabs = [
  { id: 0, label: 'All' },
  { id: 1, label: 'Websites' },
  { id: 2, label: 'Mobile' },
  { id: 3, label: 'Dashboards' },
];

function Tabs({ filterProjects }) {
  const { toggleClasses } = useComponent();
  let [activeTab, setActiveTab] = useState(tabs[0].id);

  const handleFilter = (category) => filterProjects(category);

  const handleChangeActions = (tab) => {
    setActiveTab(tab.id);
    handleFilter(tab.label);
  };

  return (
    <div className='tabs w-fit'>
      <div
        className={`tabs__wrapper flex flex-row p-1 rounded-full ${toggleClasses(
          'bg-grayblue-300',
          'bg-navy-600'
        )}`}
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.id}
            onClick={() => handleChangeActions(tab)}
            id={tab.id}
            label={tab.label}
            activeTab={activeTab}
          />
        ))}
      </div>
    </div>
  );
}

export default Tabs;
