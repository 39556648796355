import { Reorder } from 'framer-motion';
import { colVariants } from './variants';

function Col({ col = {}, animated, className, children, ...props }) {
  // Map col props to the respective Tailwind classes
  const columnClassNames = [
    col.sm ? colVariants.sm[col.sm] : '', // Get the correct class for small screens
    col.md ? colVariants.md[col.md] : '', // Get the correct class for medium screens
    col.lg ? colVariants.lg[col.lg] : '', // Get the correct class for large screens
    col.xl ? colVariants.xl[col.xl] : '', // Get the correct class for extra-large screens
  ].join(' '); // Join classes and remove empty strings

  if (animated) {
    return (
      <Reorder.Item
        className={`${columnClassNames} ${className || ''}`}
        {...props}
      >
        {children}
      </Reorder.Item>
    );
  } else {
    return (
      <div className={`${columnClassNames} ${className || ''}`} {...props}>
        {children}
      </div>
    );
  }
}

export default Col;
