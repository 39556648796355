import { useContext } from 'react';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { AuthContext } from 'react-context/AuthProvider';

export default function useAuth() {
  const auth = getAuth();
  const user = useContext(AuthContext);

  const signInWithUser = (email, password) =>
    signInWithEmailAndPassword(auth, email, password);

  const logOut = () => signOut(auth);

  return {
    user,
    auth,
    signInWithUser,
    logOut,
  };
}
