import React from 'react';

function Container({ children, className, full }) {
  return (
    <div className={`container ${className} ${full ? 'w-full' : 'w-full px-2 mx-auto md:px-6 lg:max-w-192 lg:px-[5rem]'}`}>
      {children}
    </div>
  );
}

export default Container;
