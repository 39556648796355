import { useEffect, useState } from 'react';
import {
  browserLocalPersistence,
  getAuth,
  onAuthStateChanged,
  setPersistence,
} from 'firebase/auth';
import { AuthContext } from './context';
export { AuthContext } from './context';

function AuthProvider({ children }) {
  const auth = getAuth();
  const [user, setUser] = useState();

  useEffect(() => {
    let unsubscribe;

    setPersistence(auth, browserLocalPersistence).catch((error) => {
      console.error('Failed to set persistence', error);
    });

    unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        console.log(currentUser);
        setUser(currentUser);
      } else {
        setUser(null);
      }
    });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [auth, user]);

  return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
